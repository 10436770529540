import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Loader from "./Loader";
import Header from "./Header";

const Faq = () => {
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        {/*=================================
page-title*/}

        {/*=================================
page-title */}
        {/*=================================
 faq*/}
        <section
          className="faq white-bg page-section-ptb"
          style={{ paddingTop: "170px" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="section-title text-center">
                  <h6></h6>
                  <h2 className="title-effect">Questions fréquentes</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="accordion shadow">
                  <div className="acd-group acd-active">
                    <a href="#" className="acd-heading">
                      QU'EST-CE QU'UN TRANSFORMATEUR?
                    </a>
                    <div className="acd-des">
                      <p>
                        Un transformateur est un appareil électrique (machine
                        statique) qui,par induction électromagnétique,transforme
                        l'énergie électrique à partir d'un ou plusieurs circuits
                        à un ou plusieurs autres circuits à la même fréquence.
                        En faisant varier les relations magnétiques ou des
                        valeurs de l'entrée par rapport à la sortie,un
                        transformateur produit des valeurs modifiées de tension
                        et de courant.Un transformateur fonctionne en ayant les
                        enroulements d'entrée effectués autour d'un noyau en
                        tôles en acier spécial qui transmet les impulsions du
                        courant alternatif aux enroulements de sortie.
                      </p>
                    </div>
                  </div>
                  <div className="acd-group">
                    <a href="#" className="acd-heading">
                      COMMENT SPECIFIER UN TRANSFORMATEUR?
                    </a>
                    <div className="acd-des">
                      <ul className="list list-unstyled list-mark">
                        <li>Monophasé ou triphasé.</li>
                        <li>
                          Tension(s) primaire et la connexion (par exemple,
                          delta).
                        </li>
                        <li>
                          Tension(s) secondaire et la connexion (par exemple,
                          étoile).
                        </li>
                        <li>Puissance en kVA.</li>
                        <li>Fréquence (par exemple, 60 Hz ou 50/60 Hz).</li>
                        <li>
                          Transformateur du type hermétique ou à conservateur
                          d'huile.
                        </li>
                        <li>Conditions de service.</li>
                        <li>
                          Le niveau d'échauffements (limitations, le cas
                          échéant, sont importantes).
                        </li>
                        <li>
                          Utilisation de transformateur et type (description de
                          l'application).
                        </li>
                        <li>Tension secondaire à vide.</li>
                        <li>Pourcentage de régulation.</li>
                        <li>Pourcentage d'impédance.</li>
                        <li>Restrictions dimensionnelles.</li>
                        <li>Pertes en Watt et les limites.</li>
                        <li>
                          Type de traversées (traversées porcelaine ou
                          embrochable).
                        </li>
                        <li>Dimensions de montage.</li>
                      </ul>
                    </div>
                  </div>
                  <div className="acd-group">
                    <a href="#" className="acd-heading">
                      REGULATION DE LA TENSION DANS LES TRANSFORMATEURS
                    </a>
                    <div className="acd-des">
                      <p>
                        La régulation de la tension dans les transformateurs est
                        la différence entre la tension à vide et la tension à
                        pleine charge. Elle est habituellement exprimée en
                        pourcentage: par exemple, avec un transformateur qui
                        fournit 400 volts à vide et 380 volts à pleine charge,
                        la régulation serait de 5%. La moyenne tension peut
                        aussi être inférieure ou supérieure à la tension
                        nominale, l'enroulement primaire est ensuite ajusté en
                        déplaçant la position du changeur de prise. Les
                        transformateurs électriques et d'éclairage ont
                        généralement une régulation de 2,5% à 5%, selon la
                        taille et l'application à laquelle ils sont utilisés.
                      </p>
                    </div>
                  </div>
                  <div className="acd-group">
                    <a href="#" className="acd-heading">
                      TRANSFORMATEURS EN PARALLÉLLES
                    </a>
                    <div className="acd-des">
                      <p>
                        Les transformateurs peuvent être mis en parallèle
                        lorsque leurs tensions sont égales. Si les tensions ne
                        sont pas égales, la différence entre les tensions se
                        traduira par une tension nette qui provoque un courant
                        pour circuler sur le réseau fermé entre les deux
                        transformateurs. Cela entraînera un faux chargement et,
                        s'il y a assez de différence entre les deux tensions,
                        les transformateurs, étant connecté à eux, peuvent
                        effectivement brûler sans aucune charge utile. Afin
                        d'avoir des transformateurs avec répartition de tensions
                        et de charge proportionnelle, leurs impédances doivent
                        être similaires. Dans la plupart des installations
                        commerciales une tolérance de 10% d'impédance est
                        autorisée lorsque les transformateurs sont en parallèle.
                        Les transformateurs triphasés doivent avoir une même
                        connexion et un déplacement angulaire similaire, ce qui
                        signifie que le phasage doit être le même sur chaque
                        transformateur.
                      </p>
                    </div>
                  </div>
                  <div className="acd-group">
                    <a href="#" className="acd-heading">
                      COMMENT S'APPLIQUE L'IMPEDANCE AUX TRANSFORMATEURS?
                    </a>
                    <div className="acd-des">
                      <p>
                        L'impédance est la résistance trouvée dans tous les
                        circuits où le courant alternatif est utilisé.
                        Électriquement, l'impédance est constituée de trois
                        Composants : une résistance en courant continu, une
                        inductance et une capacité. L'inductance et la capacité
                        peuvent être ajoutées directement lorsqu'ellessont
                        converties en unités souhaitée. L'un est positif et
                        l'autre est négatif. Le résultat de cette quantité est
                        ensuite ajouté vectoriellement à la résistance.
                        L'impédance de la plupart des transformateurs ordinaires
                        circule entre 3% et 6%. Les transformateurs d'impédance
                        très faible ont tendance à donner une meilleure
                        régulation. Si le secondaire du transformateur doit être
                        accidentellement en court-circuit et l'impédance du
                        transformateur est faible, il y aurait un flux de
                        courant très élevé sur les deux enroulements primaires
                        et secondaires. Cela peut provoquer un déplacement
                        mécanique des enroulements et un court-circuit entre des
                        spires adjacentes. Avec un transformateur qui a une
                        impédance de 5% et un secondaire court-circuité, le
                        courant primaire et secondaire seraient automatiquement
                        limités à 20 fois le courant normal. Si l'impédance
                        était de 10% du courant primaire, le secondaire serait
                        limitéà 10 fois le courant normal, et ainsi de suite,
                        dans certaines limites qui ne peuvent pas être expliqués
                        ici.
                      </p>
                    </div>
                  </div>
                  <div className="acd-group">
                    <a href="#" className="acd-heading">
                      QUE SIGNIFIE ONAN?
                    </a>

                    <div className="acd-des">
                      <p>
                        Le transformateur immergé est généralement refroidi avec
                        son liquide diélectrique qui est généralement une huile
                        minérale. ONAN est un mode de refroidissement qui
                        signifie huile naturelle Air naturel (circulation
                        naturelle de l'huile à l'intérieur du transformateur et
                        de l'air à l'extérieur du transformateur pourdissiper
                        l'énergie thermique produite par le transformateur. Il
                        existe d'autres modes de refroidissement: ONAF (huile
                        naturelle Air forcé); OFAF (huile forcée Air forcé);
                        ODAF (huile dirigée (Pompée) Air forcé).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 text-center mt-40">
                <h6>
                  Si vous ne trouvez pas la réponse à votre question dans notre
                  FAQ, vous pouvez toujours nous contacter directement à .
                </h6>
                <br />
                <h6>
                  <a
                    className="theme-color"
                    href="mailto:contact@sacemgroup.com"
                  >
                    {" "}
                    contact@sacemgroup.com
                  </a>{" "}
                </h6>
                <br />
                <div
                  className="row"
                  style={{
                    justifyContent: "center",
                    paddingRight: "15px",
                  }}
                >
                  <a href="/Contact" className="button button-border">
                    Nous Contacter
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
 careers*/}
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Faq;
