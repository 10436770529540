import React, { useEffect, useState } from "react";
import Loader from "../Loader";
import Header_en from "./Header_en";
import Footer_en from "./Footer_en";

const Rse_en = () => {
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    //switch language to english
    document.documentElement.lang = "en";
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header_en />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        <section
          className="page-title bg-overlay-black-60 parallax"
          data-jarallax='{"speed": 0.6}'
          /*style={{ backgroundImage: "url(/assets/images/bg/02.jpg)" }}*/
          style={{
            backgroundImage: "url(/assets/images/Entreprise/RSE.png)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>corporate social responsibility</h1>
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/en">
                      <i className="fa fa-home" /> Home
                    </a>{" "}
                    <i className="fa fa-angle-double-right" />
                  </li>
                  <li>
                    <span>C S R</span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/** */}

        <section className="page-section-ptb">
          <div className="container">
            <div
              className="row"
              style={{ textAlign: "justify", padding: "0 15px" }}
            >
              <div className="col-lg-6">
                <img
                  className="img-fluid full-width mb-20"
                  src="/assets/images/Entreprise/P2.png"
                  alt="Corporate Social Responsibility SACEM"
                />
              </div>
              <div className="col-lg-6">
                <div className="section-title">
                  {/* <h6>Know About me </h6> */}
                  <h2 className="title-effect">CSR Commitment</h2>
                  <p>
                    At SACEM Industries, we are committed to sustainable and
                    responsible development.
                  </p>
                </div>
                <p>
                  Drawing on our heritage since 1966, we integrate
                  environmental, social, and economic concerns into our strategy
                  and operations.
                </p>
                <p>
                  Our CSR commitment is based on values of respect, innovation,
                  and transparency to meet the expectations of our stakeholders
                  and contribute positively to our environment.
                </p>
                {/* <img className="mt-20" src="images/signature.png" alt /> */}
                <cite>
                  <span className="text-gray">The Chief Executive Officer</span>
                  <br />
                  <strong>KHALED KAMMOUN</strong>
                </cite>
              </div>
            </div>
            {/** */}
            <div
              className="row"
              style={{
                padding: "0 15px",
                textAlign: "justify",
                marginTop: "20px",
              }}
            >
              {/* <div className="col-lg-8 col-md-6 mt-50"> */}

              {/* </div> */}
              <div style={{ padding: "0 15px" }}>
                <h3>Our Key Commitments:</h3>
                <h5 className="mt-30">1.Responsible Governance</h5>
                <ul className="list list-hand" style={{ paddingLeft: "20px" }}>
                  <li>Adopt ethical practices in all our activities.</li>
                  <li>Encourage worker participation and consultation.</li>
                  <li>
                    Communicate reliable information with our stakeholders
                    securely.
                  </li>
                  <li>Ensure compliance with current regulations.</li>
                  <li>
                    Continuously train our teams in practices compliant with
                    international standards.
                  </li>
                </ul>
                <h5 className="mt-30">2. Environmental Protection</h5>
                <ul className="list list-hand" style={{ paddingLeft: "20px" }}>
                  <li>
                    Reduce the ecological footprint of our industrial operations
                    by optimizing energy consumption and minimizing waste.
                  </li>
                  <li>
                    Use green technologies and circular economy practices.
                  </li>
                  <li>Environmental awareness and education.</li>
                  <li>Offer recycling solutions for end-of-life components.</li>
                </ul>
              </div>
            </div>
            {/** */}
            <div
              className="row"
              style={{ padding: "0 15px", textAlign: "justify" }}
            >
              <div className="col-lg-8 col-md-6 " style={{ marginTop: "0px" }}>
                <h5 className="mt-30">3. Social and Human Commitment</h5>
                <ul className="list list-hand" style={{ paddingLeft: "20px" }}>
                  <li>
                    Ensure safe and inclusive working conditions for our
                    employees.
                  </li>
                  <li>Maintain a healthy social climate.</li>
                  <li>
                    Promote personal and professional development through
                    regular training.
                  </li>
                  <li>
                    Actively support initiatives for socio-economic development
                    of local communities.
                  </li>
                </ul>
                <h5 className="mt-30">4. Respect for Client Interests</h5>
                <ul className="list list-hand" style={{ paddingLeft: "20px" }}>
                  <li>Work fairly and equitably for all clients.</li>
                  <li>
                    Guarantee product and service quality that meets client
                    expectations.
                  </li>
                  <li>
                    Take into account client interests by providing commercial
                    offers based on clear and transparent rates.
                  </li>
                </ul>
                <h5 className="mt-30">5. Stakeholder Engagement </h5>
                <ul className="list list-hand" style={{ paddingLeft: "20px" }}>
                  <li>
                    Collaborate with our partners to promote sustainable
                    practices throughout the value chain.
                  </li>
                  <li>
                    Proactively communicate our CSR performance and regularly
                    publish sustainability reports.
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-6 mt-50 text-white pos-r">
                <div
                  className="action-box small bg-overlay-black-60 parallax"
                  style={{ background: "url(images/bg/06.jpg)" }}
                >
                  <h3 className="text-white">To achieve these objectives</h3>
                  <ul className="list list-arrow">
                    <li>
                      <p>
                        We commit to establishing clear performance indicators
                        to measure our progress.
                      </p>
                    </li>
                    <li>
                      <p>
                        A CSR committee has been created to plan, supervise, and
                        evaluate implemented initiatives.
                      </p>
                    </li>
                    <li>
                      <p>
                        We will strengthen dialogue with our stakeholders to
                        adjust our actions based on developments and
                        expectations.
                      </p>
                    </li>
                    <li>
                      <p>
                        Promote the use of environmentally friendly materials.{" "}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div>
        <Footer_en />
      </div>
    </div>
  );
};

export default Rse_en;
