import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Banner from "./Banner";
import Loader from "./Loader";
import HeaderHome from "./HeaderHome";
import Footer from "./Footer";

const Home = () => {
  const handleClick = (event) => {
    event.preventDefault(); // Prevent the default action
    console.log("Link click prevented!");
  };
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 600);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  //

  const StyledHeading = styled.h6`
    position: relative;
    font-size: 100px;
    color: #008bcd;

    &::before {
      content: "—";
      font-size: inherit;
      color: #008bcd;
      display: block;
    }
  `;
  return (
    <div>
      <Loader isVisible={isVisible} />
      <HeaderHome isVisible={isVisible} />
      <div style={{ display: isVisible ? "block" : "none" }}>
        <Banner />
        {/** <section
        className="slider-parallax typer-banner business bg-overlay-black-50 parallax"
        data-jarallax='{"speed": 0.6}'
        style={{ backgroundImage: "url(/assets/images/bg/18.jpg)" }}
      >
        <div className="slider-content-middle">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="slider-content text-center">
                  <h1 className="text-white">
                    SACEM, Make &amp;{" "}
                    <span
                      className="typer"
                      data-delay={150}
                      data-words="Move,Launch,Success"
                      data-colors="#22bef2,#22bef2,#22bef2"
                    />{" "}
                    <span
                      className="cursor"
                      data-cursordisplay="|"
                      data-owner="some-id"
                    />{" "}
                  </h1>
                  <p className="text-white mt-40">
                    "Innovative Technologies to Bring Electricity."
                  </p>
                  <a className="buttonacceuil move mt-20" href="/histoire">
                    {" "}
                    Since 1966{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>*/}

        {/*=================================banner */}
        {/*=================================about */}
        <section className="page-section-ptb">
          <div className="container">
            <div className="row">
              <div className="col-lg-6" style={{ minWidth: "56%" }}>
                <div className="section-title">
                  {/**<h2>SACEM INDUSTRIES</h2>
                  <h2 className="title-effect">
                    Technologies innovantes pour apporter l'électricité
                  </h2> */}

                  <h1 className="title-effect">SACEM INDUSTRIES</h1>
                  <StyledHeading className="h6-line"></StyledHeading>
                  <br />
                  <h5
                    style={{
                      color: "#002f6c",
                      letterSpacing: 0,
                      fontFamily: "Montserrat",
                    }}
                  >
                    Technologies innovantes pour apporter l'électricité
                  </h5>
                  <br />

                  <p style={{ textAlign: "justify" }}>
                    Depuis 1966 SACEM Industries est le leader dans la
                    fabrication et la commercialisation des transformateurs de
                    distribution électriques monophasés, triphasés et spéciaux.
                  </p>
                </div>

                <div
                  className="row  text-center"
                  style={{ paddingLeft: "15px" }}
                >
                  <div className="mt-30">
                    <a className="button button-border" href="/presentation">
                      A propos de SACEM
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 sm-mt-30" style={{ maxWidth: "44%" }}>
                <img
                  style={{ maxWidth: "100%" }}
                  src="/assets/images/Acceuil/icon SI.png"
                  alt="SACEM INDUSTRIES"
                />
                {/**
<div
                className="owl-carousel"
                data-nav-arrow="true"
                data-items={1}
                data-md-items={1}
                data-sm-items={1}
                data-xs-items={1}
                data-xx-items={1}
              >
                <div className="item">
                  <img
                    className="img-fluid full-width"
                    src="/assets/images/Acceuil/PSLIDER4.png"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="item">
                  <img
                    className="img-fluid full-width"
                    src="/assets/images/Acceuil/PSLIDER2.png"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="item">
                  <img
                    className="img-fluid full-width"
                    src="/assets/images/Acceuil/PSLIDER3.png"
                    alt=""
                    loading="lazy"
                  />
                </div>
              </div>
             */}
              </div>
            </div>
          </div>
        </section>
        {/*=================================Nos actualité -*/}

        {/*=================================Nos actualité -*/}
        <section>
          <div className="container">
            <h2 className="title-effect">Dernières actualités</h2>
            <StyledHeading></StyledHeading>
            <br />
            <div
              className="row"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <div className="col-lg-3 col-sm-6 sm-mb-30">
                <div className="about-mission-box white-bg h-100">
                  <img
                    className="img-fluid mx-auto"
                    src="/assets/images/actualité/home/Op-blanche01-01.jpg"
                    alt="site SACEM POWER SYSTEMS"
                    style={{ height: "180px" }}
                  />
                  <div className="content">
                    <p className="mb-0">
                      Action blanche pour lutte contre incendie, évacuation et
                      secourisme.
                    </p>
                    <br />
                    <a href="#" onClick={handleClick}>
                      <i className="fa fa-calendar-o" /> 02 Décembre 2024
                    </a>
                    <br />
                    <strong>Nous suivre: </strong>
                    &nbsp;
                    <a
                      href="https://www.facebook.com/sacemindustries/?locale=fr_FR"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      <i className="fa fa-facebook" />{" "}
                    </a>
                    &nbsp;
                    <a
                      href="https://fr.linkedin.com/company/sacem-group"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      <i className="fa ti-linkedin" />{" "}
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 sm-mb-30">
                <div className="about-mission-box white-bg h-100">
                  <img
                    className="img-fluid mx-auto"
                    src="/assets/images/actualité/home/ACT2.jpg"
                    alt="Renovation_bibliotheque"
                    style={{ height: "180px" }}
                  />
                  <div className="content">
                    <p className="mb-0">
                      Réaménagement de la bibliothèque publique de Tinja
                    </p>
                    <br />
                    <a href="#" onClick={handleClick}>
                      <i className="fa fa-calendar-o" /> 27 Septembre 2024
                    </a>
                    <br />
                    <strong>Nous suivre: </strong>
                    &nbsp;
                    <a
                      href="https://www.facebook.com/sacemindustries/?locale=fr_FR"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      <i className="fa fa-facebook" />{" "}
                    </a>
                    &nbsp;
                    <a
                      href="https://fr.linkedin.com/company/sacem-group"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      <i className="fa ti-linkedin" />{" "}
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6 sm-mb-30">
                <div className="about-mission-box white-bg h-100">
                  <img
                    className="img-fluid mx-auto"
                    src="/assets/images/actualité/home/sans-tabac.jpg"
                    alt="sans tabac"
                    style={{ height: "180px" }}
                  />
                  <div className="content">
                    <p className="mb-0">La journée mondiale sans tabac</p>
                    <br />
                    <br />
                    <a href="#" onClick={handleClick}>
                      <i className="fa fa-calendar-o" /> 31 Mai 2024
                    </a>
                    <br />
                    <strong>Nous suivre: </strong>
                    &nbsp;
                    <a
                      href="https://www.facebook.com/sacemindustries/?locale=fr_FR"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      <i className="fa fa-facebook" />{" "}
                    </a>
                    &nbsp;
                    <a
                      href="https://fr.linkedin.com/company/sacem-group"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      <i className="fa ti-linkedin" />{" "}
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 sm-mb-30">
                <div className="about-mission-box white-bg h-100">
                  <img
                    className="img-fluid mx-auto"
                    src="/assets/images/actualité/home/esprit1.jpg"
                    alt="municipalité de TINJAH"
                    style={{ height: "180px" }}
                  />
                  <div className="content">
                    <p className="mb-0">Accueil des étudiants d'ESPRIT</p>
                    <br />
                    <br />
                    <a href="#" onClick={handleClick}>
                      <i className="fa fa-calendar-o" /> 08 Mai 2024
                    </a>
                    <br />
                    <strong>Nous suivre: </strong>
                    &nbsp;
                    <a
                      href="https://www.facebook.com/sacemindustries/?locale=fr_FR"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      <i className="fa fa-facebook" />{" "}
                    </a>
                    &nbsp;
                    <a
                      href="https://fr.linkedin.com/company/sacem-group"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      <i className="fa ti-linkedin" />{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="row justify-content-center text-center">
            <div className="mt-30">
              <a className="button button-border" href="/actualites">
                toutes nos actualités
              </a>
            </div>
          </div>
        </section>
        {/*=================================Nos Expertise*/}
        <section className="blog white-bg page-section-ptb">
          <div className="container">
            <h2>Nos expertises</h2>
            <StyledHeading></StyledHeading>
            <div className="col-lg-12 col-md-12 text-center">
              {/** <div className="divider double" /> */}
              <br />
              <br />
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="blog-entry mb-50">
                  <div className="entry-image clearfix">
                    <div
                      className="owl-carousel bottom-center-dots"
                      data-nav-dots="ture"
                      data-items={1}
                      data-md-items={1}
                      data-sm-items={1}
                      data-xs-items={1}
                      data-xx-items={1}
                    >
                      <div className="item">
                        <img
                          className="img-fluid"
                          src="/assets/images/transformateur/Puissance/20MV.png"
                          alt="SACEM Transformateur 20MV"
                        />
                      </div>
                      <div className="item">
                        <img
                          className="img-fluid"
                          src="/assets/images/transformateur/Triphase/triphasé.png"
                          alt="SACEM Transformateur triphasé"
                        />
                      </div>
                      <div className="item">
                        <img
                          className="img-fluid"
                          src="/assets/images/transformateur/Specials/special.png"
                          alt="SACEM Transformateur special"
                        />
                      </div>
                      <div className="item">
                        <img
                          className="img-fluid"
                          src="/assets/images/transformateur/Mono/MONO.png"
                          alt="SACEM Transformateur mono"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="blog-detail">
                    <div className="entry-title mb-10">
                      <h3>Transformateur</h3>
                    </div>
                    <div className="entry-content">
                      <p style={{ textAlign: "justify" }}>
                        Chez SACEM INDUSTRIES, nous sommes engagés à offrir des
                        solutions de transformation d'énergie fiables et
                        innovantes, adaptées aux besoins spécifiques de nos
                        clients. Forts d'une expertise technique de pointe et
                        d'un savoir-faire inégalé, nous concevons et fabriquons
                        des transformateurs répondant aux plus hautes normes de
                        qualité et de performance. Notre équipe, animée par des
                        valeurs de professionnalisme, d'engagement, et de
                        travail en équipe, s'assure de vous accompagner à chaque
                        étape, de la conception à la maintenance, pour garantir
                        la durabilité de vos installations. Ensemble, bâtissons
                        un futur énergétique plus sûr et durable.
                      </p>
                    </div>
                    <div className="entry-share clearfix">
                      <div className="entry-button">
                        <a className="button arrow" href="/transformateurs">
                          Lire plus
                          <i className="fa fa-angle-right" aria-hidden="true" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ================================================ */}
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="blog-entry mb-50">
                  <div className="entry-image clearfix">
                    <div
                      className="owl-carousel bottom-center-dots"
                      data-nav-dots="ture"
                      data-items={1}
                      data-md-items={1}
                      data-sm-items={1}
                      data-xs-items={1}
                      data-xx-items={1}
                    >
                      <div className="item">
                        <img
                          className="img-fluid"
                          src="/assets/images/Power/HTA.png"
                          alt="Tableau-AIS"
                        />
                      </div>
                      <div className="item">
                        <img
                          className="img-fluid"
                          src="/assets/images/Power/ENERGY MANAGEMENT.png"
                          alt="Système de Gestion de l'Energie"
                        />
                      </div>
                      <div className="item">
                        <img
                          className="img-fluid"
                          src="/assets/images/Power/coffret protection.png"
                          alt="Coffret Protection Transformateur"
                        />
                      </div>
                      <div className="item">
                        <img
                          className="img-fluid"
                          src="/assets/images/Power/powerbc.png"
                          alt="Batterie de Condensateurs"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="blog-detail">
                    <div className="entry-title mb-10">
                      <h3>Power Systems</h3>
                    </div>
                    <div className="entry-content">
                      <p style={{ textAlign: "justify" }}>
                        Chez SACEM POWER, nous proposons des produits en basse
                        et moyenne tension. Notre offre couvre les produits de
                        protection, de distribution et d'optimisation
                        électrique, tels que les cellules 36kV avec jeux de
                        barres dans l'air, les batteries de condensateurs, les
                        coffrets de protection de transformateur, les coffrets
                        de comptage, ainsi que les coffrets TUR et TIPI, conçus
                        pour répondre aux exigences les plus élevées en matière
                        de performance et de sécurité. Notre engagement repose
                        sur la qualité, l'innovation et l'accompagnement de nos
                        clients à chaque étape, afin d'assurer la fiabilité et
                        la durabilité de leurs installations énergétiques.
                      </p>
                    </div>
                    <div className="entry-share clearfix">
                      <div className="entry-button">
                        <a className="button arrow" href="/tableauMT-BT">
                          Lire plus
                          <i className="fa fa-angle-right" aria-hidden="true" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="blog-entry mb-50">
                  <div className="entry-image clearfix">
                    <div
                      className="owl-carousel bottom-center-dots"
                      data-nav-dots="ture"
                      data-items={1}
                      data-md-items={1}
                      data-sm-items={1}
                      data-xs-items={1}
                      data-xx-items={1}
                    >
                      <div className="item">
                        <img
                          className="img-fluid"
                          src="/assets/images/services/SAV2.png"
                          alt="Service SACEM"
                        />
                      </div>
                      <div className="item">
                        <img
                          className="img-fluid"
                          src="/assets/images/services/SAV.png"
                          alt="Service SACEM"
                        />
                      </div>
                      <div className="item">
                        <img
                          className="img-fluid"
                          src="/assets/images/services/SV2.png"
                          alt="Service SACEM"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="blog-detail">
                    <div className="entry-title mb-10">
                      <h3>SAV & Réparation</h3>
                    </div>
                    <div className="entry-content">
                      <p style={{ textAlign: "justify" }}>
                        Chez SACEM INDUSTRIES, nous mettons notre expertise au
                        service de la maintenance et de la réparation de
                        transformateurs, garantissant une performance optimale
                        et une durée de vie prolongée de vos équipements. Grâce
                        à une équipe expérimentée et des interventions rapides,
                        nous assurons un service de haute qualité, respectueux
                        des normes de sécurité et de fiabilité. Guidés par les
                        valeurs de professionnalisme, d'engagement, et de
                        travail en équipe, nous sommes à vos côtés pour
                        minimiser les temps d'arrêt et maximiser l'efficacité de
                        vos installations énergétiques.
                        <br />
                        <br />
                      </p>
                    </div>
                    <div className="entry-share clearfix">
                      <div className="entry-button">
                        <a className="button arrow" href="/services">
                          Lire plus
                          <i className="fa fa-angle-right" aria-hidden="true" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="blog-entry mb-50">
                  <div className="entry-image clearfix">
                    <div
                      className="owl-carousel bottom-center-dots"
                      data-nav-dots="ture"
                      data-items={1}
                      data-md-items={1}
                      data-sm-items={1}
                      data-xs-items={1}
                      data-xx-items={1}
                    >
                      <div className="item">
                        <img
                          className="img-fluid"
                          src="/assets/images/training/training1.png"
                          alt="SACEM Training"
                        />
                      </div>
                      <div className="item">
                        <img
                          className="img-fluid"
                          src="/assets/images/training/training2.png"
                          alt="SACEM Training"
                        />
                      </div>
                      <div className="item">
                        <img
                          className="img-fluid"
                          src="/assets/images/training/training3.png"
                          alt="SACEM Training"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="blog-detail">
                    <div className="entry-title mb-10">
                      <h3>Formation</h3>
                    </div>
                    <div className="entry-content">
                      <p style={{ textAlign: "justify" }}>
                        Chez SACEM Training, notre mission est de former des
                        professionnels compétents et prêts à relever les défis
                        du secteur. Grâce à des programmes de formation sur
                        mesure, animés par des experts passionnés, nous offrons
                        à nos apprenants les compétences techniques et pratiques
                        nécessaires pour exceller dans leur domaine. Notre
                        approche allie professionnalisme, engagement, et travail
                        en équipe, garantissant une expérience d'apprentissage
                        enrichissante et adaptée aux besoins du marché.
                        Rejoignez-nous pour développer vos talents et façonner
                        ensemble l'avenir de votre carrière.
                      </p>
                    </div>
                    <div className="entry-share clearfix">
                      <div className="entry-button">
                        <a className="button arrow" href="/formation">
                          Lire plus
                          <i className="fa fa-angle-right" aria-hidden="true" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*================================= Certificat*/}
            <div className="row mt-70">
              <div className="col-lg-12 col-md-12">
                <h2 className="tile-headline">Nos Certificats </h2>
                <StyledHeading></StyledHeading>
                {/**<div className="divider double" />*/}
                <br />
                <br />
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="clients-list">
                <div
                  className="owl-carousel"
                  data-nav-dots="false"
                  data-items={3}
                  data-md-items={4}
                  data-sm-items={3}
                  data-xs-items={2}
                  data-xx-items={2}
                >
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/Certificat/ISO9001.png"
                      alt="ISO9001"
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/Certificat/ISO14001.png"
                      alt="ISO14001"
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/Certificat/ISO45001.png"
                      alt="ISO45001"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/*=================================clients */}
            <div className="row mt-70">
              <div className="col-lg-12 col-md-12">
                <h2>Nos Clients </h2>
                <StyledHeading></StyledHeading>
                {/**<div className="divider double" /> */}
                <br />
                <br />
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="clients-list">
                <div
                  className="owl-carousel"
                  data-nav-dots="false"
                  data-items={5}
                  data-md-items={4}
                  data-sm-items={3}
                  data-xs-items={2}
                  data-xx-items={2}
                >
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO1.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO2.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO3.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO4.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO5.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO6.png"
                      alt=""
                    />
                  </div>

                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO9.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO10.png"
                      alt=""
                    />
                  </div>
                  {/**11-20 */}
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO11.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO12.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO13.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO14.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO15.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO16.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO17.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO18.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO19.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO20.png"
                      alt=""
                    />
                  </div>
                  {/**21-30 */}
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO21.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO22.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO23.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO24.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO25.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO26.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO27.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO28.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO29.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO30.png"
                      alt=""
                    />
                  </div>
                  {/** 31-40*/}
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO31.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO32.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO33.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO34.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO35.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO36.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO37.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO38.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO39.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO40.png"
                      alt=""
                    />
                  </div>
                  {/**41-50 */}
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO41.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO42.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO43.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO44.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO45.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO46.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO47.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO48.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO49.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO50.png"
                      alt=""
                    />
                  </div>
                  {/**51-55 */}
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO51.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO52.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO53.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO54.png"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/clients/LO55.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            {/** fin client corosel */}
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
