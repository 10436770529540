import React, { useEffect, useState } from "react";
//import "./Banner.css"; // Import CSS file for styles

const Banner = () => {
  const backgroundImages = [
    "/assets/images/slider_bannier/PSLIDER2.png",
    "/assets/images/slider_bannier/PSLIDER3.png",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % backgroundImages.length);
    }, 3000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, [backgroundImages.length]);

  return (
    <section
      className="slider-parallax typer-banner business bg-overlay-black-50 parallax"
      id="dynamic-background"
      style={{
        backgroundImage: `url(${backgroundImages[currentIndex]})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        transition: "background-image 1s ease-in-out", // Smooth transition
      }}
    >
      <div className="slider-content-middle">
        <div className="container" style={{ maxWidth: "100%" }}>
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="slider-content text-center">
                <h3
                  className="text-white"
                  style={{
                    fontSize: "65px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontFamily: '"Dosis", sans-serif',
                    lineHeight: "100px",
                  }}
                >
                  Collaboration &{" "}
                  <span
                    className="typer"
                    data-delay="150"
                    data-words="Respect,Innovation"
                    data-colors="#22bef2,#22bef2,#22bef2"
                  ></span>{" "}
                  <span
                    className="cursor"
                    data-cursorDisplay="|"
                    data-owner="some-id"
                  ></span>
                </h3>
                <p className="text-white mt-40">
                  "Innovative Technologies to Bring Electricity."
                </p>
                <a className="buttonacceuil move mt-20" href="/histoire">
                  Since 1966
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
