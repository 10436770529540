import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CountUp from "react-countup";
import Loader from "../Loader";
import Header_en from "./Header_en";
import Footer_en from "./Footer_en";

const Presentation_en = () => {
  // const styles = {
  //   h6: {
  //     fontSize: "16px",
  //     fontStyle: "normal",
  //     fontWeight: 600,
  //     lineHeight: "18px",
  //   },
  // };
  const StyledHeading = styled.h6`
    position: relative;
    font-size: 100px;
    color: #008bcd;

    &::before {
      content: "—";
      font-size: inherit;
      color: #008bcd;
      display: block;
    }
  `;
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    //switch language to english
    document.documentElement.lang = "en";
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header_en />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        {/*=================================
page-title*/}
        <section
          className="page-title bg-overlay-black-60 parallax"
          data-jarallax='{"speed": 0.6}'
          /*style={{ backgroundImage: "url(/assets/images/bg/02.jpg)" }}*/
          style={{
            backgroundImage:
              "url(/assets/images/Entreprise/entreprise-prensentation.png)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>Presentation</h1>
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/en">
                      <i className="fa fa-home" /> Home
                    </a>{" "}
                    <i className="fa fa-angle-double-right" />
                  </li>
                  <li>
                    <span>Presentation</span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
page-title */}
        <section className="page-section-ptb" style={{ paddingBottom: "0px" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="section-title">
                  <h1 className="title-effect">About SACEM</h1>
                  <StyledHeading className="h6-line"></StyledHeading>

                  <p style={{ textAlign: "justify" }}>
                    <br />
                    Since 1966, SACEM Industries has been Tunisia's leading
                    manufacturer and distributor of single-phase, three-phase
                    and special electrical transformers.
                  </p>
                </div>
                <p style={{ textAlign: "justify" }}>
                  Located in Menzel Bourguiba-Tinja, our factory extends over a
                  6-hectare site, with 18,000 m² of covered space, and a team of
                  450 qualified employees dedicated to satisfying our customers
                  in over 30 countries. Our engineers, experts in product
                  innovation, work with passion to offer ever more effective
                  solutions. SACEM Industries continually invests in staff
                  training to ensure that our manufacturing standards are among
                  the leaders in the industry.
                </p>
              </div>
              <div className="col-lg-6 sm-mt-30">
                <div
                  className="owl-carousel"
                  data-nav-arrow="true"
                  data-items={1}
                  data-md-items={1}
                  data-sm-items={1}
                  data-xs-items={1}
                  data-xx-items={1}
                >
                  <div className="item">
                    <img
                      className="img-fluid full-width"
                      src="/assets/images/Entreprise/P2.png"
                      alt="Transformateur SACEM"
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid full-width"
                      src="/assets/images/Entreprise/P1.png"
                      alt="Transformateur SACEM"
                    />
                  </div>

                  <div className="item">
                    <img
                      className="img-fluid full-width"
                      src="/assets/images/Entreprise/P3.png"
                      alt="Cellules SACEM Power"
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid full-width"
                      src="/assets/images/Entreprise/P4.png"
                      alt="SACEM Industries"
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid full-width"
                      src="/assets/images/Entreprise/P5.png"
                      alt=" SACEM Power"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <br />
              <p style={{ paddingLeft: "15px" }}>
                Thanks to the other SACEM GROUP entities, we can also offer you
                :
                <br />
                <br />{" "}
                <span style={{ fontWeight: "bold" }}>SACEM Service :</span>A
                responsive after-sales service, with an experienced team on hand
                to maintain and repair your transformers as quickly and safely
                as possible.
                <br /> <br />
                <span style={{ fontWeight: "bold" }}>SACEM Training :</span> The
                first training center of its kind in Africa, SACEM Training
                shares over 50 years of expertise in electrical transformers,
                with programs that comply with international standards.
              </p>
              <p style={{ paddingLeft: "15px" }}>
                Our services and expertise make SACEM Industries the partner of
                choice for your energy projects, combining excellence and
                sustainability.{" "}
              </p>
            </div>
          </div>
        </section>
        {/**** */}
        <section className="page-section-ptb1">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="section-title"
                  style={{ marginBottom: "0px", paddingTop: "40px" }}
                >
                  <h1 className="title-effect">SACEM in numbers</h1>
                  <StyledHeading className="h6-line"></StyledHeading>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/** */}

        <section className=" text-center">
          <div
            className="container"
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div
              className="row"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div
                className="col-lg-3 col-sm-6 xs-mb-30"
                // style={{ maxWidth: "22.5%" }}
              >
                <div className="counter theme-color text-center">
                  <label style={{ height: "60px", paddingTop: "5%" }}>
                    <h4>Transformers</h4>
                  </label>
                  <br />
                  <div className="row" style={{ justifyContent: "center" }}>
                    <div>
                      <div>
                        <CountUp
                          className="timer theme-color"
                          end={10000}
                          duration={3}
                          separator=""
                        />
                      </div>

                      {/* <div style={{ paddingTop: "30px" }}>
                      <span>
                        <h6></h6>
                      </span>
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
              {/** */}
              <div
                className="col-lg-3 col-sm-6 xs-mb-30"
                // style={{ maxWidth: "22.5%" }}
              >
                <div className="counter theme-color text-center">
                  <label style={{ height: "60px" }}>
                    <h4>Annual Production Capacity</h4>
                  </label>
                  <br />
                  <div>
                    <div className="row" style={{ justifyContent: "center" }}>
                      <div>
                        <CountUp
                          className="timer theme-color"
                          end={2000}
                          duration={3}
                          separator=""
                          style={{ width: "100%" }}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          <h6>&emsp;MVA</h6>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/** */}
              <div
                className="col-lg-3 col-sm-6 xs-mb-30"
                // style={{ maxWidth: "22.5%" }}
              >
                <div className="counter theme-color text-center">
                  <label style={{ height: "60px" }}>
                    <h4>Production area</h4>
                  </label>

                  <div>
                    <div className="row" style={{ justifyContent: "center" }}>
                      <div>
                        <CountUp
                          className="timer theme-color"
                          end={60000}
                          duration={3}
                          separator=""
                          style={{ width: "100%" }}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          <h6>&emsp;m2</h6>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/** */}
              {/* <div
                className="col-lg-3 col-sm-6 xs-mb-30"
                style={{ maxWidth: "17.5%" }}
              >
                <div className="counter theme-color text-center">
                  <div className="divider" />
                  <label  style={{ height: "60px" }}>
                    <h4>Export vers </h4>
                  </label>
                  <br />
                  <div
                    style={{
                      display: "flex",
                      justifyItems: "center",
                    }}
                  >
                    <div style={{ width: "70%" }}>
                      <CountUp
                        className="timer theme-color"
                        end={30}
                        duration={3}
                        separator=""
                      />
                    </div>

                    <div style={{ paddingTop: "30px" }}>
                      <span>
                        <h6>Pays</h6>
                      </span>
                    </div>
                  </div>
                </div>
              </div> */}
              {/** */}
              {/* <div className="col-lg-3 col-sm-6" style={{ maxWidth: "15%" }}>
                <div className="counter theme-color text-center">
                  <div className="divider" />
                  <label  style={{ height: "60px" }}>
                    <h4>Employers</h4>
                  </label>
                  <br />
                  <CountUp
                    className="timer theme-color"
                    end={450}
                    duration={3}
                    separator=""
                  />
                </div>
              </div> */}
            </div>

            <div
              className="row"
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: "30px",
              }}
            >
              {/** */}
              <div className="col-lg-3 col-sm-6 xs-mb-30">
                <div className="counter theme-color text-center">
                  <label>
                    <h4>Employees</h4>
                  </label>
                  <br />
                  <CountUp
                    className="timer theme-color"
                    end={450}
                    duration={3}
                    separator=""
                  />
                </div>
              </div>
              {/** */}
              <div className="col-lg-3 col-sm-6 xs-mb-30 ">
                <div className="counter theme-color text-center">
                  <label>
                    <h4>Export to</h4>
                  </label>
                  <div className="row" style={{ justifyContent: "center" }}>
                    <div>
                      <div style={{ width: "50%" }}>
                        <CountUp
                          className="timer theme-color"
                          end={30}
                          duration={3}
                          separator=""
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyItems: "center",
                      }}
                    >
                      <div style={{ paddingTop: "30px" }}>
                        <span>
                          <h6>&emsp;Country</h6>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/**** */}
        <section className="page-section-ptb1">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="section-title"
                  style={{ marginBottom: "0px", paddingTop: "40px" }}
                >
                  <h1 className="title-effect">SACEM values</h1>
                  <StyledHeading className="h6-line"></StyledHeading>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/** */}
        <section
          className="service white-bg "
          style={{ paddingBottom: "80px" }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-title text-center">
                  <h1>
                    Our values today are the foundation of our excellence
                    tomorrow: CRI
                  </h1>
                </div>
              </div>
            </div>
            <div
              className="row"
              style={{ paddingLeft: "35px", paddingRight: "35px" }}
            >
              <p>
                At the heart of our identity lie three core values that guide
                each of our actions and define who we are. Born from a
                collective consultation with our employees, these values reflect
                our DNA and our vision for the future.
              </p>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-4">
                <div className="mb-30">
                  <h1>
                    01<span className="theme-color">.</span>
                  </h1>
                  <h3>Collaboration</h3>
                  <p style={{ textAlign: "justify" }}>
                    Collaboration is our primary value. We firmly believe that
                    the greatest achievements stem from teamwork, skill sharing,
                    and the synergy between talents. Every day, we nurture an
                    environment where mutual support and cooperation enable us
                    to achieve excellence.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="mb-30">
                  <h1>
                    02<span className="theme-color">.</span>
                  </h1>
                  <h3>Respect</h3>
                  <p style={{ textAlign: "justify" }}>
                    Respect forms the foundation of all our relationships. It
                    manifests in our daily interactions, in our consideration
                    for each individual, and in our commitment to creating an
                    inclusive and caring work environment. This respect
                    naturally extends to our clients, partners, and our entire
                    ecosystem.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="text-left">
                  <h1>
                    03<span className="theme-color">.</span>
                  </h1>
                  <h3>Innovation</h3>
                  <p style={{ textAlign: "justify" }}>
                    Innovation is our driving force for progress. We encourage
                    creativity, embrace change, and constantly seek to push the
                    boundaries of what's possible. This quest for innovation
                    allows us to stay at the forefront of our industry while
                    delivering increasingly relevant solutions to our clients.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <p>
                These values are not mere words, but living principles that
                animate our daily lives and shape our future.
              </p>
            </div>
          </div>
        </section>
      </div>
      <div>
        <Footer_en />
      </div>
    </div>
  );
};

export default Presentation_en;
