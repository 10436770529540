import React from "react";

const NosCommerciaux_en = () => {
  return (
    <div>
      <div className="row mt-60">
        <div className="col-lg-6 col-md-6 sm-mb-30">
          <div className="card">
            <div className="card-body">
              <div className="team team-list">
                <div className="team-photo">
                  <img
                    className="img-fluid mx-auto"
                    src="/assets/images/Commerciaux/hbaili_nizar.png"
                    alt="Nizar HBAILI"
                  />
                </div>
                <div className="team-description">
                  <div className="team-info" style={{ height: "100px" }}>
                    <h5>
                      <a href="#/"> Nizar HBAILI</a>
                    </h5>
                    <span>Area sales Manager Africa</span>
                  </div>
                  <div className="team-contact">
                    <span className="call fa fa-phone">
                      {" "}
                      <a href="tel:21628505116">+(216) 28 505 116</a>
                    </span>
                    <span className="call fa fa-whatsapp">
                      <a href="https://wa.me/2250747127686">
                        +(225) 07 47 12 76 86
                      </a>
                    </span>
                    <span className="email">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div style={{ width: "10%", color: "#029acb " }}>
                          <i className="fa fa-envelope-o" />
                        </div>
                        <div>
                          <a href="mailto:Nizar.HBAILI@sacemgroup.com">
                            {" "}
                            Nizar.HBAILI@sacemgroup.com
                          </a>
                        </div>
                      </div>
                    </span>
                  </div>
                  <div className="social-icons social-border rounded color-hover clearfix">
                    <ul>
                      <li className="social-linkedin">
                        <a href="https://www.linkedin.com/in/nizar-h-ba%C3%AFli-38746a12b/">
                          <i className="fa fa-linkedin" />
                        </a>
                      </li>
                      <li className="social-linkedin">
                        <a href="https://wa.me/21628505116">
                          <i className="fa fa-whatsapp" />
                        </a>
                      </li>
                    </ul>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 sm-mb-30">
          <div className="card">
            <div className="card-body">
              <div className="team team-list">
                <div className="team-photo">
                  <img
                    className="img-fluid mx-auto"
                    src="/assets/images/Commerciaux/oussema_kaabi.png"
                    alt="Nizar HBAILI"
                  />
                </div>
                <div className="team-description">
                  <div className="team-info" style={{ height: "100px" }}>
                    <h5>
                      <a href="#/"> Oussama KAABI</a>
                    </h5>
                    <span>Area Sales Manager algeria-Africa</span>
                  </div>
                  <div className="team-contact">
                    <span className="call fa fa-phone">
                      {" "}
                      <a href="tel:21629493151">+(216) 29 493 151</a>
                    </span>
                    <span className="call fa fa-whatsapp">
                      <a href="https://wa.me/21629493151">+(216) 29 493 151</a>
                    </span>
                    <span className="email">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div style={{ width: "10%", color: "#029acb " }}>
                          <i className="fa fa-envelope-o" />
                        </div>
                        <div>
                          <a href="mailto:Oussama.KAABI@sacemgroup.com">
                            {" "}
                            Oussama.KAABI@sacemgroup.com
                          </a>
                        </div>
                      </div>
                    </span>
                  </div>
                  <div className="social-icons social-border rounded color-hover clearfix">
                    <ul>
                      <li className="social-linkedin">
                        <a href="https://fr.linkedin.com/company/sacem-group">
                          <i className="fa fa-linkedin" />
                        </a>
                      </li>
                      <li className="social-linkedin">
                        <a href="https://wa.me/21629493151">
                          <i className="fa fa-whatsapp" />
                        </a>
                      </li>
                    </ul>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row" style={{ marginTop: "30px" }}>
        <div className="col-lg-6 col-md-6 sm-mb-30">
          <div className="card">
            <div className="card-body">
              <div className="team team-list">
                <div className="team-photo">
                  <img
                    className="img-fluid mx-auto"
                    src="/assets/images/Commerciaux/nasser_abdulhamid.png"
                    alt="Abdlhamid NASSR"
                  />
                </div>
                <div className="team-description">
                  <div className="team-info" style={{ height: "100px" }}>
                    <h5>
                      <a href="#/">Abdlhamid NASSR</a>
                    </h5>
                    <span>Production & sales Manager in Libya </span>
                  </div>
                  <div className="team-contact">
                    <span className="call fa fa-phone">
                      {" "}
                      <a href="tel:21629922626">+(216) 29 922 626</a>
                    </span>
                    <span className="call fa fa-whatsapp">
                      {" "}
                      <a href="https://wa.me/21629922626">+(216) 29 922 626</a>
                    </span>
                    <span className="email">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div style={{ width: "10%", color: "#029acb " }}>
                          <i className="fa fa-envelope-o" />
                        </div>
                        <div>
                          <a href="mailto:Abdulhamid.NASSR@sacemgroup.com">
                            {" "}
                            Abdulhamid.NASSR@sacemgroup.com
                          </a>
                        </div>
                      </div>
                    </span>
                  </div>
                  <div className="social-icons social-border rounded color-hover clearfix">
                    <ul>
                      <li className="social-linkedin">
                        <a href="https://www.linkedin.com/in/abdulhamid-nassr-bbb9b3313?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app">
                          <i className="fa fa-linkedin" />
                        </a>
                      </li>
                      <li className="social-linkedin">
                        <a href="https://wa.me/21629922626">
                          <i className="fa fa-whatsapp" />
                        </a>
                      </li>
                    </ul>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 sm-mb-30">
          <div className="card">
            <div className="card-body">
              <div className="team team-list">
                <div className="team-photo">
                  <img
                    className="img-fluid mx-auto"
                    src="/assets/images/Commerciaux/rahma zalani.jpg"
                    alt="Nizar HBAILI"
                  />
                </div>
                <div className="team-description">
                  <div className="team-info" style={{ height: "100px" }}>
                    <h5>
                      <a href="#/"> Rahma ZAALANI</a>
                    </h5>
                    <span>Local Sales Manager Tunisia</span>
                  </div>
                  <div className="team-contact">
                    <span className="call fa fa-phone">
                      {" "}
                      <a href="tel:21629591207">+(216) 29 591 207</a>
                    </span>
                    <span className="call fa fa-whatsapp">
                      <a href="https://wa.me/21629591207">+(216) 29 591 207</a>
                    </span>
                    <span className="email">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div style={{ width: "10%", color: "#029acb " }}>
                          <i className="fa fa-envelope-o" />
                        </div>
                        <div>
                          <a href="mailto:Rahma.ZAALANI@sacemgroup.com">
                            {" "}
                            Rahma.ZAALANI@sacemgroup.com
                          </a>
                        </div>
                      </div>
                    </span>
                  </div>
                  <div className="social-icons social-border rounded color-hover clearfix">
                    <ul>
                      <li className="social-linkedin">
                        <a href="https://www.linkedin.com/in/zaalani-rahma-16a91a188/">
                          <i className="fa fa-linkedin" />
                        </a>
                      </li>
                      <li className="social-linkedin">
                        <a href="https://wa.me/21629591207">
                          <i className="fa fa-whatsapp" />
                        </a>
                      </li>
                    </ul>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NosCommerciaux_en;
