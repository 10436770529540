import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Loader from "./Loader";
import ReCAPTCHA from "react-google-recaptcha"; // Import reCAPTCHA component

const Contact = () => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  const [formData, setFormData] = useState({
    name: "",
    lastName: "", // Add last name field
    poste: "", // Add poste field
    email: "",
    phone: "",
    message: "",
  });

  // State to handle the uploaded file
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState(""); // State to handle file errors

  // State to handle the response or error messages
  const [responseMessage, setResponseMessage] = useState("");
  const [Nom, setNom] = useState("");
  const [isLoading, setIsLoading] = useState(false); // to manage loader status

  const recaptchaSiteKey = process.env.REACT_APP_reCaptcha;
  const recaptchaSiteKey_localhost = process.env.REACT_APP_reCaptcha_localhost;

  const [errors, setErrors] = useState({});
  const [Captcha, setCaptcha] = useState(true); // State to track captcha change and disable button
  const [isSubmitted, setIsSubmitted] = useState(false); // State to track form submission
  const maxFileSize = 1 * 1024 * 1024; // 1 MB in bytes
  // Handle captcha value
  const captchavalue = (e) => {
    setCaptcha(false);
  };

  // Handler to update state when input values change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle file input change
  const handleFileChange = (e) => {
    const allowedTypes = ["application/pdf"];
    const file = e.target.files[0];
    setSelectedFile(e.target.files[0]);
    // Check if the file size exceeds 1 MB (1 MB = 1048576 bytes)
    if (file) {
      // Check if the file type is allowed
      if (!allowedTypes.includes(file.type)) {
        setErrors({
          ...errors,
          file: "Votre fichier doit être au format PDF.",
        });
        setSelectedFile(null); // Reset selected file if invalid type
      }
      // Check if the file size exceeds 1 MB
      else if (file.size > maxFileSize) {
        setErrors({
          ...errors,
          file: "La taille du fichier ne doit pas dépasser 1 Mo.",
        });
        setSelectedFile(null); // Reset selected file if too large
      } else {
        // If both type and size are valid, clear errors and set the file
        setErrors({ ...errors, file: "" });
        setSelectedFile(file);
      }
    }
  };

  // Validate the form fields
  const validateForm = () => {
    let formErrors = {};

    if (!formData.name.trim()) formErrors.name = "Name is required";
    if (!formData.lastName.trim())
      formErrors.lastName = "Last name is required"; // Validate last name
    // if (!formData.poste.trim()) formErrors.poste = "Poste is required"; // Validate poste
    if (!formData.email.trim()) formErrors.email = "Email is required";
    if (!formData.phone.trim()) formErrors.phone = "Phone is required";
    if (!formData.message.trim()) formErrors.message = "Message is required";

    setErrors(formErrors);

    // If there are no errors, return true
    return Object.keys(formErrors).length === 0;
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // display the loader

    // Validate form fields before submission
    if (!validateForm()) {
      setIsLoading(false);
      return;
    }

    // Prevent submission if file exceeds the size limit
    if (fileError) {
      setIsLoading(false);
      alert(fileError); // Optionally show an alert to the user
      return;
    }

    console.log("Form validation successful, preparing request"); // Debug

    // Prepare the form data for 'multipart/form-data' request
    const formDataToSend = new FormData();
    formDataToSend.append("name", formData.name);
    formDataToSend.append("lastName", formData.lastName); // Add last name to the formData
    formDataToSend.append("poste", formData.poste); // Add poste to the formData
    formDataToSend.append("email", formData.email);
    formDataToSend.append("phone", formData.phone);
    formDataToSend.append("message", formData.message);

    if (selectedFile) {
      formDataToSend.append("file", selectedFile);
    }

    console.log("Form data prepared:", formDataToSend); // Debug

    // API endpoint where data will be sent
    const apiUrl = process.env.REACT_APP_API_PowerAuto_Contact;

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        body: formDataToSend, // Send 'multipart/form-data'
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Response from server:", data); // Debug server response
        setNom(data.data.name);
        setResponseMessage("Form submitted successfully!");
        setIsSubmitted(true);
      } else {
        setResponseMessage("There was an error submitting the form.");
      }
    } catch (error) {
      setResponseMessage("An error occurred: " + error.message);
    }

    // Optionally, reset the form
    setFormData({
      name: "",
      lastName: "",
      poste: "",
      email: "",
      phone: "",
      message: "",
    });
    setTimeout(() => setIsLoading(false), 500); // hide the loader
  };

  return (
    <div>
      <Header />
      <Loader isVisible={isVisible} />

      {/** */}
      <div
        className="container"
        style={{ paddingTop: "190px", paddingBottom: "40px" }}
      >
        <div className="col-lg-12 col-md-12">
          <div className="row">
            <div className="col-sm-12">
              {!isSubmitted ? (
                // Form is displayed when isSubmitted is false
                <div>
                  {!isLoading ? (
                    <div>
                      <h3 className="mb-40">Nous Contacter</h3>

                      <div id="formmessage">
                        {responseMessage || "Success/Error Message Goes Here"}
                      </div>
                      <form>
                        <div className="contact-form clearfix">
                          <div>
                            {errors.name && (
                              <span className="error-text">{errors.name}</span>
                            )}
                            <input
                              id="name"
                              type="text"
                              value={formData.name}
                              onChange={handleInputChange}
                              placeholder="Name*"
                              className="form-control"
                              name="name"
                              required
                            />
                          </div>

                          <div>
                            {errors.lastName && (
                              <span className="error-text">
                                {errors.lastName}
                              </span>
                            )}
                            <input
                              id="lastName"
                              type="text"
                              value={formData.lastName}
                              onChange={handleInputChange}
                              placeholder="Prénom*"
                              className="form-control"
                              name="lastName"
                              required
                            />
                          </div>

                          <div>
                            {errors.email && (
                              <span className="error-text">{errors.email}</span>
                            )}
                            <input
                              type="email"
                              placeholder="Email*"
                              value={formData.email}
                              onChange={handleInputChange}
                              required
                              className="form-control"
                              name="email"
                            />
                          </div>

                          <div>
                            {errors.phone && (
                              <span className="error-text">{errors.phone}</span>
                            )}
                            <input
                              type="text"
                              placeholder="Phone*"
                              value={formData.phone}
                              onChange={handleInputChange}
                              required
                              className="form-control"
                              name="phone"
                            />
                          </div>

                          <div className="section-field textarea">
                            {errors.message && (
                              <span className="error-text">
                                {errors.message}
                              </span>
                            )}
                            <textarea
                              className="form-control input-message"
                              placeholder="Message*"
                              rows={7}
                              name="message"
                              value={formData.message}
                              onChange={handleInputChange}
                              required
                            />
                          </div>

                          <div
                            className="section-field submit-button"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            {/* reCAPTCHA Widget */}
                            <ReCAPTCHA
                              sitekey={recaptchaSiteKey}
                              onChange={captchavalue}
                            />
                            <br />
                            <input
                              type="hidden"
                              name="action"
                              defaultValue="sendEmail"
                            />
                            <button
                              className="button-border button"
                              onClick={handleSubmit}
                              disabled={Captcha || errors.file}
                              style={{
                                backgroundColor:
                                  Captcha || errors.file ? "#e0e0e0" : "white",
                                color:
                                  Captcha || errors.file ? "#aaaaaa" : "black",
                              }}
                            >
                              Valider
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src="/assets/images/pre-loader/loader.gif"
                        alt="Loading..."
                        style={{
                          paddingTop: "20px",
                          height: "150px",
                          width: "125px",
                        }}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {!isLoading && (
                    <div style={{ paddingTop: "50px" }}>
                      <h3>
                        <span>{Nom},</span>
                      </h3>
                      <h5>
                        Nous avons bien reçu votre demande et vous recevrez une
                        réponse très bientôt.
                      </h5>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Contact;
