import React, { useEffect, useState } from "react";
import Loader from "../Loader";
import Form_carriers_en from "./Form_carriers_en";
import Footer_en from "./Footer_en";
import Header_en from "./Header_en";

const Rhcarriers_en = () => {
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    //switch language to english
    document.documentElement.lang = "en";
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header_en />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        {/*=================================
  page-title*/}

        {/*=================================
  page-title */}
        {/*=================================
   careers*/}
        <section
          className="careers white-bg page-section-ptb"
          style={{ paddingTop: "170px" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="section-title text-center">
                  <h6></h6>
                  <h2 className="title-effect">
                    Find the job that's right for you and apply!
                  </h2>
                  <p>
                    At SACEM Industries, we believe our success is built on
                    three core values that guide our daily actions:
                  </p>
                  <p className></p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <h4>COLLABORATION</h4>
                <p>
                  Our strength lies in teamwork. We foster knowledge sharing and
                  mutual support among our 450 employees. Every team member
                  contributes to our collective success.
                </p>
                <h4 className="mt-30">RESPECT </h4>
                <p>
                  Respect is at the heart of our relationships, both internally
                  and with our clients and partners. We value diversity of
                  talent, perspectives, and experiences, creating an inclusive
                  work environment where everyone can thrive.
                </p>
                <h4 className="mt-30">INNOVATION </h4>
                <p>
                  Since 1966, innovation has been our driving force. We invest
                  in continuous training for our teams and encourage creativity
                  to develop increasingly efficient energy solutions. Joining
                  SACEM Industries means being part of building the future of
                  the electrical sector.
                </p>
              </div>
              <div className="col-lg-6 sm-mt-30">
                <div>
                  <img
                    className="img-fluid full-width"
                    src="/assets/images/Entreprise/P2.png"
                    alt="Transformateur SACEM"
                    style={{ height: "416px", width: "780px" }}
                  />
                </div>
              </div>
            </div>
            <br />
            <p>
              Share our values? Join a dynamic team where your talent and
              potential will be valued. Together, let's continue to illuminate
              the world with excellence and passion.
            </p>
            <div>
              {/**div of offers */}
              <div>
                {/**
                <div className="accordion shadow">
                  <div className="acd-group acd-active">
                    <a className="acd-heading" href="#/">
                      Ingénieur études
                    </a>
                    <div className="acd-des">
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <span className="mb-10">
                            <b>Localisation : </b>Tunisie{" "}
                          </span>
                          <span>
                            <b>Département : </b>Etudes{" "}
                          </span>
                          <p className="mt-20"></p>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <h4 className="mb-20 theme-color">
                            Description de l'emploi
                          </h4>
                          <ul className="list list-unstyled mb-30">
                            <li>
                              <i className="fa fa-angle-right" />
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <h4 className="mb-20 theme-color">
                            Ce que nous offrons
                          </h4>
                          <ul className="list list-unstyled mb-30">
                            <li>
                              <i className="fa fa-angle-right" />
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <p className="mt-20 mb-20">
                            Les candidats intéressés et éligibles sont invités à
                            envoyer leur CV accompagné de leurs informations
                            personnelles à l'adresse e-mail suivante:{" "}
                            <a href="mailto:contact@sacemgroup.com">
                              contact@sacemgroup.com
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 */}

                {/** 
                <div className="accordion shadow">
                  <div className="acd-group acd-active">
                    <a className="acd-heading" href="#/">
                      Ingénieur Méthodes
                    </a>
                    <div className="acd-des">
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <span className="mb-10">
                            <b>Localisation : </b>Tunisie{" "}
                          </span>
                          <span>
                            <b>Département : </b>Etudes{" "}
                          </span>
                          <p className="mt-20"></p>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <h4 className="mb-20 theme-color">
                            Description de l'emploi
                          </h4>
                          <ul className="list list-unstyled mb-30">
                            <li>
                              <i className="fa fa-angle-right" />
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <h4 className="mb-20 theme-color">
                            Ce que nous offrons
                          </h4>
                          <ul className="list list-unstyled mb-30">
                            <li>
                              <i className="fa fa-angle-right" />
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <p className="mt-20 mb-20">
                            Les candidats intéressés et éligibles sont invités à
                            envoyer leur CV accompagné de leurs informations
                            personnelles à l'adresse e-mail suivante:{" "}
                            <a href="mailto:contact@sacemgroup.com">
                              contact@sacemgroup.com
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                */}
                {/** 
                <div className="accordion shadow">
                  <div className="acd-group acd-active">
                    <a className="acd-heading" href="#/">
                      Ingénieur Qualité
                    </a>
                    <div className="acd-des">
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <span className="mb-10">
                            <b>Localisation : </b>Tunisie{" "}
                          </span>
                          <span>
                            <b>Département : </b>Etudes{" "}
                          </span>
                          <p className="mt-20"></p>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <h4 className="mb-20 theme-color">
                            Description de l'emploi
                          </h4>
                          <ul className="list list-unstyled mb-30">
                            <li>
                              <i className="fa fa-angle-right" />
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <h4 className="mb-20 theme-color">
                            Ce que nous offrons
                          </h4>
                          <ul className="list list-unstyled mb-30">
                            <li>
                              <i className="fa fa-angle-right" />
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <p className="mt-20 mb-20">
                            Les candidats intéressés et éligibles sont invités à
                            envoyer leur CV accompagné de leurs informations
                            personnelles à l'adresse e-mail suivante:{" "}
                            <a href="mailto:contact@sacemgroup.com">
                              contact@sacemgroup.com
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                */}
                {/** */}
              </div>
              <div style={{ paddingTop: "20px" }}>
                <Form_carriers_en />
              </div>
            </div>
          </div>
        </section>
        {/*=================================
   careers*/}
      </div>
      <div>
        <Footer_en />
      </div>
    </div>
  );
};

export default Rhcarriers_en;
