import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Loader from "../Loader";
import Header_en from "./Header_en";
import Footer_en from "./Footer_en";

const Sav_en = () => {
  const StyledHeading = styled.h6`
    position: relative;
    font-size: 100px;
    color: #008bcd;

    &::before {
      content: "—";
      font-size: inherit;
      color: #008bcd;
      display: block;
    }
  `;
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header_en />
      </div>
      <Loader isVisible={isVisible} />
      <div style={{ paddingTop: "120px" }}>
        {/*=================================
 service*/}
        <section className="service white-bg page-section-ptb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-title text-center">
                  <h2 className="title-effect">After-sales service & Repair</h2>
                </div>
              </div>
            </div>
            <h4 style={{ color: "black" }}>
              SACEM Industries offers a complete range of technical services:
            </h4>
            <br />

            {/* =========================================== */}
            <div className="row">
              <div className="col-lg-6 col-sm-6 mb-30">
                <div className="media border h-100 p-4">
                  <span className="media-step theme-color mr-4">1</span>
                  <div className="media-body">
                    <h4>Transformer diagnostics </h4>
                    <p className="mb-0">
                      One of the key tasks performed by our experts is
                      conducting diagnostics on transformers, encompassing the
                      following:
                    </p>
                    <br />
                    <h6>Offline transformer diagnostics </h6>
                    <ul class="list list-unstyled list-hand ">
                      <li>Visual inspection.</li>
                      <li>Oil level check.</li>
                      <li>Measurement of insulation resistance.</li>
                      <li>Ground resistance testing.</li>
                      <li>Inspection of paint coatings.</li>
                    </ul>
                    <br />
                    <h6>Energized transformer diagnostics</h6>
                    <ul class="list list-unstyled list-hand ">
                      <li>Temperatures monitoring.</li>
                      <li>Detection of unusual vibrations.</li>
                      <li>Thermal imaging diagnostics.</li>
                      <li>Harmonics measurement on the Low-Voltage Side.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 mb-30">
                <div className="media border h-100 p-4">
                  <span className="media-step theme-color mr-4">2</span>
                  <div className="media-body">
                    <h4>Preventive Maintenance and Servicing</h4>
                    <p className="mb-0">
                      The after-sales service offers preventive maintenance
                      programs, conducted either on-site or at the factory,
                      including:
                    </p>
                    <br />
                    <ul class="list list-unstyled list-hand ">
                      <li>Cleaning and dust removal.</li>
                      <li>Inspection of transformer accessories.</li>
                      <li>Replacement of the sealing gaskets.</li>
                      <li>Replacement of the saturated Silica Gel.</li>
                      <li>Paint Touch-Up.</li>
                      <li>Inspection of MV/LV electrical connections.</li>
                      <li>Electrical continuity testing.</li>
                      <li>Measurement of insulation resistance.</li>
                      <li>Oil sample analysis.</li>
                      <li>Oil treatment.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-sm-6 xs-mb-30">
                <div className="media border h-100 p-4">
                  <span className="media-step theme-color mr-4">3</span>
                  <div className="media-body">
                    <h4> Capacitor Banks Diagnostics</h4>
                    <p className="mb-0">Our after-sales service provides:</p>
                    <br />
                    <ul class="list list-unstyled list-hand ">
                      <li>Visual Inspection.</li>
                      <li>Inspection of the electrical connections.</li>
                      <li>Verification of the electrical components.</li>
                      <li>Network quality analysis.</li>
                      <li>Power Factor (Cos Φ) verification.</li>
                      <li>Cleaning and dust removal.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 xs-mb-30">
                <div className="media border h-100 p-4">
                  <span className="media-step theme-color mr-4">4</span>
                  <div className="media-body">
                    <h4>Repair</h4>

                    <p className="mb-0">
                      SACEM Industries repairs all brands of:
                    </p>
                    <br />
                    <ul class="list list-unstyled list-hand ">
                      <li>
                        Distribution transformer with a voltage class up to 36
                        kV.
                      </li>
                      <li>
                        Power transformer with a power rating up to 30 MVA and a
                        voltage class up to 36 kV.
                      </li>
                      <li>Capacitor banks.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <section>
              <div className="row mt-100">
                <div className="col-lg-12">
                  <h2 style={{ color: "#00356a" }}>
                    After-sales service & Repair Representative
                  </h2>
                  <StyledHeading className="h6-line"></StyledHeading>
                  <br />
                </div>
                <div className="col-lg-6 col-md-6 sm-mb-30">
                  <div className="team team-list">
                    <div className="team-photo">
                      <img
                        className="img-fluid mx-auto"
                        src="/assets/images/Commerciaux/anis_tbarki.png"
                        alt="Anis TABARKI"
                      />
                    </div>
                    <div className="team-description">
                      <div className="team-info">
                        <h5>
                          <a href="team-single.html">Anis TABARKI</a>
                        </h5>
                      </div>
                      <div className="team-contact">
                        <span className="call fa fa-phone">
                          {" "}
                          <a href="tel:21628505129">(+216) 28 505 129</a>
                        </span>

                        <span className="email">
                          {" "}
                          <i className="fa fa-envelope-o" />
                          <a href="mailto:Anis.TABARKI@sacemgroup.com">
                            {" "}
                            Anis.TABARKI@sacemgroup.com
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div
              className="entry-button"
              style={{ marginLeft: 20, marginTop: "40px" }}
            >
              <a className="button button-border" href="/en/contact">
                Contact us
              </a>
            </div>
          </div>
        </section>
        {/*==============service===
         <section className="white-bg" style={{ paddingBottom: "80px" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 align-self-center">
                <div className="section-title">
                  <h2>SAV Sacem</h2>
                  <p>
                    Notre service après-vente est le partenaire incontournable
                    pour garantir la performance, la fiabilité et la longévité
                    des installations de distribution électrique. Grâce à notre
                    expertise et notre réactivité et efficience, nous aidons nos
                    clients à minimiser les risques de pannes et maximiser la
                    fiabilité de leurs investissements. Nous vous offrons des
                    contrats de maintenance personnalisés, adaptés aux
                    caractéristiques uniques de votre parc de transformateurs et
                    à vos besoins spécifiques.
                  </p>
                </div>
              </div>

              <div className="col-lg-6 sm-mt-30">
                <img
                  src="/assets/images/Products/SAV.png"
                  className="img-fluid full-width"
                  alt
                />
              </div>
            </div>
            <div className="entry-button" style={{ marginLeft: 20 }}>
              <a className="button button-border" href="/en/contact">
                Nous Contacter
              </a>
            </div>
          </div>
        </section>
        
        */}
      </div>
      <div>
        <Footer_en />
      </div>
    </div>
  );
};

export default Sav_en;
