import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Form_carriers from "./Form_carriers";
import Header from "./Header";
import Loader from "./Loader";

const Pfebook = () => {
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        {/*=================================
page-title*/}

        {/*=================================
page-title */}
        {/*=================================
 careers*/}
        <section
          className="careers white-bg page-section-ptb"
          style={{ paddingTop: "170px" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="section-title text-center">
                  <h6></h6>
                  <h2 className="title-effect">Projet PFE</h2>
                  <p className></p>
                </div>
              </div>
            </div>
            <div>
              <div>
                {/** */}
                <div className="accordion shadow">
                  <div className="acd-group acd-active">
                    <a className="acd-heading" href="#">
                      PFE-01: Application de dimensionnement d'un transformateur
                    </a>
                    <div className="acd-des">
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <span>
                            <b>Department : </b>IT{" "}
                          </span>
                          <span>
                            <b>Objectif : </b>L'objectif principal du projet est
                            de développer une application capable de
                            dimensionner des transformateurs électriques tout en
                            optimisant les coûts associés. Cette application
                            vise à être un outil intuitif pour les ingénieurs,
                            facilitant la prise de décision technique.
                          </span>
                          <p className="mt-20" />
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <h4 className="mb-20 theme-color">
                            Les objectif de l’application{" "}
                          </h4>
                          <ul className="list list-unstyled mb-30">
                            <li>
                              <i className="fa fa-angle-right" /> Concevoir une
                              application capable de :
                            </li>
                            <li>
                              -Dimensionner un transformateur en fonction des
                              paramètres fournis (puissance, tension, fréquence,
                              etc.).
                            </li>
                            <li>
                              -Calculer les pertes et l'efficacité énergétique.
                            </li>
                            <li>
                              -Optimiser les coûts, incluant les coûts d'achat,
                              d'installation et d'exploitation.
                            </li>
                            <li>
                              <i className="fa fa-angle-right" /> Fournir une
                              interface utilisateur intuitive et ergonomique.{" "}
                            </li>
                            <li>
                              <i className="fa fa-angle-right" /> Intégrer des
                              fonctionnalités de simulation pour comparer
                              différentes configurations
                            </li>
                            <li>
                              <i className="fa fa-angle-right" /> Générer des
                              rapports automatisés.
                            </li>
                            <li>
                              <i className="fa fa-angle-right" /> Générer des
                              plans de fabrication des semis finis.
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <h4 className="mb-20 theme-color">
                            Partie fonctionnel
                          </h4>
                          <ul className="list list-unstyled mb-30">
                            <li>
                              <i className="fa fa-angle-right" /> Saisie des
                              paramètres techniques du transformateur
                              (puissance, tension primaire et secondaire, type
                              d'utilisation, etc.).
                            </li>
                            <li>
                              <i className="fa fa-angle-right" />{" "}
                              Dimensionnement automatique en fonction des normes
                              et des contraintes imposées.
                            </li>
                            <li>
                              <i className="fa fa-angle-right" /> Calcul des
                              coûts d’investissement et d’exploitation.
                            </li>
                            <li>
                              <i className="fa fa-angle-right" /> Comparaison de
                              plusieurs scénarios
                            </li>
                            <li>
                              <i className="fa fa-angle-right" /> Propositions
                              d’alternatives optimales pour réduire les coûts.
                            </li>
                            <li>
                              <i className="fa fa-angle-right" /> Analyse des
                              pertes (fer, cuivre, charge partielle, etc.).
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <p className="mt-20 mb-20">
                            Les candidats intéressés sont invités à envoyer leur
                            CV à l'adresse e-mail suivante :
                          </p>
                          <a href="mailto:recrutement@sacemgroup.com">
                            recrutement@sacemgroup.com
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="acd-group">
                    <a className="acd-heading" href="#">
                      PFE-02: Chiffrage des coûts de transformateurs
                    </a>
                    <div className="acd-des">
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <span>
                            <b>Department: </b>IT{" "}
                          </span>
                          <span>
                            <b>Objectif: </b>le projet est de développer une
                            application permettant le chiffrage des coûts de
                            transformateurs. Cette application devra se
                            connecter à Navision pour récupérer les
                            nomenclatures et articles liés, et générer une
                            impression des coûts calculés.{" "}
                          </span>
                          <p className="mt-20" />
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <h4 className="mb-20 theme-color">
                            Les objectif de l’application
                          </h4>
                          <ul className="list list-unstyled mb-30">
                            <li>
                              <i className="fa fa-angle-right" /> Récupérer les
                              données des articles et des nomenclatures et les
                              clients depuis Navision.
                            </li>
                            <li>
                              <i className="fa fa-angle-right" /> Calculer
                              automatiquement les coûts des transformateurs à
                              partir des nomenclatures et des paramètres de
                              production.
                            </li>
                            <li>
                              <i className="fa fa-angle-right" /> Générer des
                              rapports détaillés des coûts, imprimables et
                              exportables.
                            </li>
                            <li>
                              <i className="fa fa-angle-right" /> Offrir une
                              interface backend pour configurer l’application :
                              gestion des articles, charges, utilisateurs et
                              paramètres généraux.
                            </li>
                            <li>
                              <i className="fa fa-angle-right" /> Intégrer une
                              composante d’intelligence artificielle (IA) pour
                              fournir des analyses prédictives, des
                              recommandations et une optimisation des coûts.
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <h4 className="mb-20 theme-color">
                            Partie fonctionnel
                          </h4>
                          <ul className="list list-unstyled mb-30">
                            <li>
                              <i className="fa fa-angle-right" />{" "}
                              Authentification sécurisée avec gestion des rôles
                              (administrateur, utilisateur standard, etc.).
                            </li>
                            <li>
                              <i className="fa fa-angle-right" /> Connexion à
                              Navision via API REST ou SOAP.
                            </li>
                            <li>
                              <i className="fa fa-angle-right" />{" "}
                              Synchronisation des articles : prix, dimensions,
                              stocks, etc.
                            </li>
                            <li>
                              <i className="fa fa-angle-right" /> Récupération
                              des nomenclatures associées aux transformateurs.
                            </li>
                            <li>
                              <i className="fa fa-angle-right" /> Ajout et
                              gestion des charges fixes ou variables
                              (main-d’œuvre, transport, énergie, etc.).
                            </li>
                            <li>
                              <i className="fa fa-angle-right" /> Paramétrage
                              des coefficients ou des règles pour le calcul des
                              charges.
                            </li>
                            <li>
                              <i className="fa fa-angle-right" /> Calcul
                              automatique des coûts basé sur :(La nomenclature
                              sélectionnée,Les prix des articles récupérés
                              depuis Navision ou configurés dans le backend,Les
                              charges additionnelles paramétrées dans le
                              backend).
                            </li>
                            <li>
                              <i className="fa fa-angle-right" /> Possibilité
                              d’ajouter des ajustements manuels (marges,
                              remises, etc.)
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <p className="mt-20 mb-20">
                            Les candidats intéressés sont invités à envoyer leur
                            CV à l'adresse e-mail suivante:
                          </p>
                          <a href="mailto:recrutement@sacemgroup.com">
                            recrutement@sacemgroup.com
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/** */}

                {/** */}

                {/** */}
              </div>
              <div style={{ paddingTop: "20px" }}>
                <Form_carriers />
              </div>
            </div>
          </div>
        </section>
        {/*=================================
 careers*/}
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Pfebook;
