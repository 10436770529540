import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Loader from "./Loader";
import Header from "./Header";
import Footer from "./Footer";

const Transformateur_DIST_24 = () => {
  const StyledHeading = styled.h6`
    position: relative;
    font-size: 100px;
    color: #008bcd;

    &::before {
      content: "—";
      font-size: inherit;
      color: #008bcd;
      display: block;
    }
  `;
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header />
      </div>
      <Loader isVisible={isVisible} />
      {/** 
    <Headerfortest isVisible={isVisible} />
      <Header />
    */}
      <div style={{ display: isVisible ? "block" : "none" }}>
        {/*=================================
page-title*/}
        <section
          className="page-title bg-overlay-black-60 parallax"
          style={{
            backgroundImage:
              "url(/assets/images/transformateur/Triphase/slider-triphase-36-24.png)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>SACEM INDUSTRIES</h1>
                  <p>innovative Technologies to Bring Electricity</p>
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/">
                      <i className="fa fa-home" /> Accueil
                    </a>{" "}
                    <i className="fa fa-angle-double-right" />
                  </li>
                  <li>
                    <span>
                      Transformateur de distribution Triphasé classe 24KV
                    </span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
page-title */}
        {/*=================================
 feature */}
        <section className="service white-bg page-section-ptb">
          <div className="container">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                className="row justify-content-center"
                style={{ width: "85%" }}
              >
                <div className="col-lg-8">
                  <div className="section-title text-center">
                    <h2 className="title-effect">
                      Transformateur de distribution Triphasé classe 24KV
                    </h2>
                  </div>
                </div>
              </div>
              <div className="row justify-content-end">
                <div className="entry-button" style={{ alignSelf: "center" }}>
                  <a
                    className="button button-border"
                    href="/transformateur_dist_36"
                  >
                    <div style={{ padding: "0.5rem" }}>classe 36KV</div>
                  </a>
                </div>
              </div>
            </div>
            {/* ============================================ */}
            {/*=====================================*/}
            {/**<section className="page-section-ptb"> */}
            <section>
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-sm-6 mb-30 ">
                    {/** */}
                    <div
                      className="rotateInDownLeft wow"
                      style={{
                        visibility: "visible",
                        animationName: "rotateInDownLeft",
                      }}
                    >
                      <div
                        className="media border p-4"
                        style={{ minHeight: "355px" }}
                      >
                        <div className="feature-icon media-icon mr-4">
                          <span className="ti-settings theme-color1" />
                        </div>
                        <div className="media-body">
                          <h5 className="text-back">
                            CARACTÉRISTIQUES GÉNÉRALES
                          </h5>
                          <br />
                          <p className="mb-0">
                            Transformateur triphasé immergé dans l’huile:
                          </p>
                          <br />
                          <ul className="list list-unstyled list-hand">
                            <li>Norme CEI 60076.</li>
                            <li>
                              Remplissage intégral, hermétique ou respirant.
                            </li>

                            <li>Refroidissement naturel type ONAN.</li>
                            <li>Installation intérieure/extérieure.</li>
                            <li>
                              Couleur: RAL7035 (autres couleurs sur demande).
                            </li>

                            <li>Protection contre la corrosion: C5H.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/** */}
                  <div className="col-lg-6 col-sm-6 mb-30 ">
                    {/** */}
                    <div
                      className="rotateInDownRight wow"
                      style={{
                        visibility: "visible",
                        animationName: "rotateInDownRight",
                      }}
                    >
                      <div
                        className="media border p-4"
                        style={{ minHeight: "355px" }}
                      >
                        <div className="feature-icon media-icon mr-4">
                          <span className="ti-bolt theme-color1" />
                        </div>
                        <div className="media-body">
                          <h5 className="text-back">TENSIONS DE SERVICES</h5>
                          <br />
                          <ul className="list list-unstyled list-hand">
                            <li>Classe: 24kV.</li>
                            <li>
                              Tension primaire: 5.5 kV, 10 kV, 11kV, 15 kV et 20
                              kV.
                            </li>
                            <li>Couplages usuels selon CEI 60076-1.</li>
                            <li>Fréquence: 50Hz, 60Hz.</li>
                            <li>Conducteur: Cuivre (Aluminium en option).</li>
                            <li>
                              Diélectrique liquide: Huile Minérale selon CEI
                              60296.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/** */}
                  </div>
                  <div className="col-lg-6 col-sm-6 xs-mb-30">
                    {/** */}
                    <div
                      className="rotateInUpLeft wow"
                      style={{
                        visibility: "visible",
                        animationName: "rotateInUpLeft",
                      }}
                    >
                      <div
                        className="media border p-4"
                        style={{ minHeight: "570px" }}
                      >
                        <div className="feature-icon media-icon mr-4">
                          <span className="ti-package theme-color1" />
                        </div>
                        <div className="media-body">
                          <h5 className="text-back">ÉQUIPEMENTS DE BASE </h5>
                          <br />
                          <ul className="list list-unstyled list-hand">
                            <li>Anneaux de levage.</li>
                            <li>Traversées MT en porcelaine.</li>
                            <li>Plaque signalétique.</li>
                            <li>Prises de mise à la terre.</li>
                            <li>Commutateur de variation hors charge.</li>
                            <li>Traversées BT en porcelaine.</li>
                            <li>Vanne de traitement.</li>
                            <li>Vanne de vidange.</li>
                            <li>Galets de roulement.</li>
                            <li>Soupape de sureté.</li>
                            <li>Orifice de remplissage.</li>
                            <li>Doigt de gant.</li>
                          </ul>
                          <p style={{ fontWeight: "bold" }}>
                            Note : les options ci-dessus ne sont pas
                            exhaustives.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6">
                    {/** */}
                    <div
                      className="rotateInUpRight wow"
                      style={{
                        visibility: "visible",
                        animationName: "rotateInUpRight",
                      }}
                    >
                      <div
                        className="media border p-4"
                        style={{ minHeight: "570px" }}
                      >
                        <div className="feature-icon media-icon mr-4">
                          <span className="ti-list theme-color1" />
                        </div>
                        <div className="media-body">
                          <h5 className="text-back">ÉQUIPEMENTS OPTIONNELS</h5>
                          <br />
                          <ul className="list list-unstyled list-hand">
                            <li>Bornes embrochables MT.</li>
                            <li>
                              Connecteurs embrochables MT en équerre ou droit.
                            </li>
                            <li>Parafoudres.</li>
                            <li>
                              Système de verrouillage des Bornes embrochables.
                            </li>
                            <li>Capots de protection.</li>
                            <li>Dispositif de protection (DMCR ou DGPT2).</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/** */}
                  </div>
                </div>
              </div>
              <div>
                {/*=================================
 Tableau */}
                <section id="tab36" className="page-section-ptb data-table">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <h2 style={{ color: "#00356a" }}>
                          CARACTERISTIQUES ELECTRIQUES GARANTIES
                        </h2>
                        <StyledHeading className="h6-line"></StyledHeading>
                        <br />
                        <div className="table-responsive">
                          <table className="table table-1 table-bordered table-striped">
                            <thead>
                              <tr>
                                <td colSpan={3}>
                                  Puissance {"{"}kVA{"}"}
                                </td>
                                <td>50</td>
                                <td>100</td>
                                <td>160</td>
                                <td>250</td>
                                <td>315</td>
                                <td>400</td>
                                <td>500</td>
                                <td>630</td>
                                <td>800</td>
                                <td>1000</td>
                                <td>1250</td>
                                <td>1600</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td colSpan={3}>
                                  Pertes à vide {"{"}W{"}"}
                                </td>
                                <td>220</td>
                                <td>330</td>
                                <td>530</td>
                                <td>600</td>
                                <td>840</td>
                                <td>930</td>
                                <td>1180</td>
                                <td>1320</td>
                                <td>1600</td>
                                <td>1800</td>
                                <td>2160</td>
                                <td>2540</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>
                                  Pertes en charge {"{"}W{"}"}
                                </td>
                                <td>1320</td>
                                <td>2100</td>
                                <td>2600</td>
                                <td>3800</td>
                                <td>4400</td>
                                <td>5100</td>
                                <td>6400</td>
                                <td>7900</td>
                                <td>12000</td>
                                <td>13300</td>
                                <td>17600</td>
                                <td>20500</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>
                                  Courant à vide {"{"}%{"}"}
                                </td>
                                <td>2.9</td>
                                <td>2.5</td>
                                <td>2.3</td>
                                <td>2.1</td>
                                <td>2</td>
                                <td>1.9</td>
                                <td>1.9</td>
                                <td>1.8</td>
                                <td>2.5</td>
                                <td>2.4</td>
                                <td>2.2</td>
                                <td>2</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>
                                  Impedance de court circuit {"{"}%{"}"}
                                </td>
                                <td>4.00</td>
                                <td>4.00</td>
                                <td>4.00</td>
                                <td>4.00</td>
                                <td>4.00</td>
                                <td>4.00</td>
                                <td>4.00</td>
                                <td>4.00</td>
                                <td>4.50</td>
                                <td>5.00</td>
                                <td>5.50</td>
                                <td>6.00</td>
                              </tr>
                              <tr>
                                <td>
                                  Chute de tension {"{"}%{"}"}
                                </td>
                                <td colSpan={2}>Cos φ= 0.8</td>
                                <td>3.92</td>
                                <td>3.73</td>
                                <td>3.51</td>
                                <td>3.46</td>
                                <td>3.39</td>
                                <td>3.32</td>
                                <td>3.32</td>
                                <td>3.31</td>
                                <td>3.78</td>
                                <td>4.00</td>
                                <td>4.37</td>
                                <td>4.62</td>
                              </tr>
                              <tr>
                                <td>
                                  Chute de tension {"{"}%{"}"}
                                </td>
                                <td colSpan={2}>Cos φ= 1</td>
                                <td>2.69</td>
                                <td>2.16</td>
                                <td>1.69</td>
                                <td>1.59</td>
                                <td>1.47</td>
                                <td>1.35</td>
                                <td>1.35</td>
                                <td>1.33</td>
                                <td>1.59</td>
                                <td>1.45</td>
                                <td>1.55</td>
                                <td>1.45</td>
                              </tr>
                              <tr>
                                <td>
                                  Rendement {"{"}%{"}"} (à Cos φ=0.8)
                                </td>
                                <td>Charge en %</td>
                                <td>50</td>
                                <td>97.32</td>
                                <td>97.91</td>
                                <td>98.19</td>
                                <td>98.47</td>
                                <td>98.48</td>
                                <td>98.64</td>
                                <td>98.63</td>
                                <td>98.71</td>
                                <td>98.58</td>
                                <td>98.73</td>
                                <td>98.70</td>
                                <td>98.82</td>
                              </tr>
                              <tr>
                                <td>
                                  Rendement {"{"}%{"}"} (à Cos φ=0.8)
                                </td>
                                <td>Charge en %</td>
                                <td>75</td>
                                <td>96.89</td>
                                <td>97.54</td>
                                <td>97.97</td>
                                <td>98.21</td>
                                <td>98.28</td>
                                <td>98.44</td>
                                <td>98.43</td>
                                <td>98.50</td>
                                <td>98.29</td>
                                <td>98.48</td>
                                <td>98.42</td>
                                <td>98.56</td>
                              </tr>
                              <tr>
                                <td>
                                  Rendement {"{"}%{"}"} (à Cos φ=0.8)
                                </td>
                                <td>Charge en %</td>
                                <td>100</td>
                                <td>96.29</td>
                                <td>97.05</td>
                                <td>97.61</td>
                                <td>97.85</td>
                                <td>97.96</td>
                                <td>98.15</td>
                                <td>98.14</td>
                                <td>98.20</td>
                                <td>97.92</td>
                                <td>98.15</td>
                                <td>98.06</td>
                                <td>98.23</td>
                              </tr>
                            </tbody>
                          </table>
                          <br />
                          <br />
                          <br />
                          <h3 style={{ color: "#00356a" }}>
                            DIMENSIONS ET POIDS
                          </h3>
                          <StyledHeading className="h6-line"></StyledHeading>
                          <br />
                          <table className="table table-1 table-bordered table-striped">
                            <thead>
                              <tr>
                                <td>Puissance (kVA)</td>
                                <td>50</td>
                                <td>100</td>
                                <td>160</td>
                                <td>250</td>
                                <td>315</td>
                                <td>400</td>
                                <td>500</td>
                                <td>630</td>
                                <td>800</td>
                                <td>1000</td>
                                <td>1250</td>
                                <td>1600</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>L</td>
                                <td>850</td>
                                <td>900</td>
                                <td>980</td>
                                <td>1350</td>
                                <td>1200</td>
                                <td>1300</td>
                                <td>1560</td>
                                <td>1570</td>
                                <td>1580</td>
                                <td>1720</td>
                                <td>2000</td>
                                <td>2120</td>
                              </tr>
                              <tr>
                                <td>I</td>
                                <td>600</td>
                                <td>630</td>
                                <td>850</td>
                                <td>800</td>
                                <td>880</td>
                                <td>900</td>
                                <td>920</td>
                                <td>1030</td>
                                <td>1050</td>
                                <td>1200</td>
                                <td>1230</td>
                                <td>1300</td>
                              </tr>
                              <tr>
                                <td>H</td>
                                <td>1240</td>
                                <td>1300</td>
                                <td>1300</td>
                                <td>1500</td>
                                <td>1550</td>
                                <td>1420</td>
                                <td>1700</td>
                                <td>1720</td>
                                <td>1800</td>
                                <td>1750</td>
                                <td>1850</td>
                                <td>2180</td>
                              </tr>
                              <tr>
                                <td>E</td>
                                <td>520</td>
                                <td>520</td>
                                <td>520</td>
                                <td>520</td>
                                <td>670</td>
                                <td>670</td>
                                <td>670</td>
                                <td>820</td>
                                <td>820</td>
                                <td>820</td>
                                <td>820</td>
                                <td>820</td>
                              </tr>
                              <tr>
                                <td>Poids Huile (Kg)</td>
                                <td>120</td>
                                <td>155</td>
                                <td>185</td>
                                <td>260</td>
                                <td>285</td>
                                <td>290</td>
                                <td>380</td>
                                <td>400</td>
                                <td>440</td>
                                <td>510</td>
                                <td>820</td>
                                <td>960</td>
                              </tr>
                              <tr>
                                <td>Poids Total (kg)</td>
                                <td>450</td>
                                <td>585</td>
                                <td>820</td>
                                <td>1100</td>
                                <td>1240</td>
                                <td>1320</td>
                                <td>1700</td>
                                <td>1800</td>
                                <td>2050</td>
                                <td>2530</td>
                                <td>3550</td>
                                <td>4240</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="entry-button" style={{ marginLeft: 20 }}>
                      <a className="button button-border" href="/contact">
                        Nous Contacter
                      </a>
                    </div>
                    <div className="entry-button" style={{ marginLeft: 20 }}>
                      <a
                        className="button button-border"
                        href="/assets/brochures/catalogue transformateurs 2024 source.pdf"
                      >
                        Catalogue
                      </a>
                    </div>
                  </div>
                </section>
                {/*=================================
 Tableau */}
              </div>
            </section>
          </div>
        </section>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Transformateur_DIST_24;
