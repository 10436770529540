import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "./Header";
import Loader from "./Loader";
import Footer from "./Footer";

const Sav = () => {
  const StyledHeading = styled.h6`
    position: relative;
    font-size: 100px;
    color: #008bcd;

    &::before {
      content: "—";
      font-size: inherit;
      color: #008bcd;
      display: block;
    }
  `;
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header />
      </div>
      <Loader isVisible={isVisible} />
      <div style={{ paddingTop: "120px" }}>
        {/*=================================
 service*/}
        <section className="service white-bg page-section-ptb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-title text-center">
                  <h2 className="title-effect">SAV & Réparation</h2>
                </div>
              </div>
            </div>
            <h4 style={{ color: "black" }}>
              SACEM Industries propose une gamme complète d’interventions
              techniques:
            </h4>
            <br />

            {/* =========================================== */}
            <div className="row">
              <div className="col-lg-6 col-sm-6 mb-30">
                <div className="media border h-100 p-4">
                  <span className="media-step theme-color mr-4">1</span>
                  <div className="media-body">
                    <h4>Diagnostic des transformateurs </h4>
                    <p className="mb-0">
                      L'une des missions de nos experts est d'effectuer des
                      diagnostics sur les transformateurs, couvrant:
                    </p>
                    <br />
                    <h6>Transformateur en arrêt</h6>
                    <ul class="list list-unstyled list-hand ">
                      <li>Inspection visuelle.</li>
                      <li>Contrôle du niveau d'huile.</li>
                      <li>Mesure des résistances d'isolement.</li>
                      <li>Mesure de la résistance de la terre.</li>
                      <li>Contrôle des revêtements de peinture.</li>
                    </ul>
                    <br />
                    <h6>Transformateur en service</h6>
                    <ul class="list list-unstyled list-hand ">
                      <li>Vérification des températures.</li>
                      <li>Détection des vibrations inhabituelles.</li>
                      <li>Diagnostic par caméras thermiques.</li>
                      <li>Mesure des harmoniques côté basse tension.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 mb-30">
                <div className="media border h-100 p-4">
                  <span className="media-step theme-color mr-4">2</span>
                  <div className="media-body">
                    <h4>Entretien et Maintenance préventive </h4>
                    <p className="mb-0">
                      Le SAV propose des programmes de maintenance préventive,
                      sur site ou en usine, comprennant :
                    </p>
                    <br />
                    <ul class="list list-unstyled list-hand ">
                      <li>Nettoyage et dépoussièrage.</li>
                      <li>Inspection des accessoires des transformateurs.</li>
                      <li>Changement des joints.</li>
                      <li>Changement du silica gel.</li>
                      <li>Retouche peinture.</li>
                      <li>Controle des connections electriques MT/BT.</li>
                      <li>Contrôle de la continuité électrique.</li>
                      <li>Contrôle des résistances d'isolement.</li>

                      <li>Analyse des échantillons d'huile.</li>
                      <li>Traitement d'huile.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-sm-6 xs-mb-30">
                <div className="media border h-100 p-4">
                  <span className="media-step theme-color mr-4">3</span>
                  <div className="media-body">
                    <h4>Diagnostic des batteries de condensateurs </h4>
                    <p className="mb-0">Notre service après-vente assure :</p>
                    <br />
                    <ul class="list list-unstyled list-hand ">
                      <li>Inspection visuelle</li>
                      <li>Vérifications des connexions électriques.</li>
                      <li>Vérifications des composants électriques.</li>
                      <li>Analyse de la qualité de réseaux.</li>
                      <li>Vérification cos Φ.</li>

                      <li>Nettoyage et dépoussiérage.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 xs-mb-30">
                <div className="media border h-100 p-4">
                  <span className="media-step theme-color mr-4">4</span>
                  <div className="media-body">
                    <h4>Réparation</h4>

                    <p className="mb-0">
                      SACEM Industries assure la réparation de toutes les
                      marques de :
                    </p>
                    <br />
                    <ul class="list list-unstyled list-hand ">
                      <li>
                        Transformateurs de classe jusqu'à 36 kV et d'une
                        puissance allant jusqu'à 30 MVA .
                      </li>
                      <li>Batteries de condensateurs.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <section>
              <div className="row mt-100">
                <div className="col-lg-12">
                  <h2 style={{ color: "#00356a" }}>Commercial SAV</h2>
                  <StyledHeading className="h6-line"></StyledHeading>
                  <br />
                </div>
                <div className="col-lg-6 col-md-6 sm-mb-30">
                  <div className="team team-list">
                    <div className="team-photo">
                      <img
                        className="img-fluid mx-auto"
                        src="/assets/images/Commerciaux/anis_tbarki.png"
                        alt="Anis TABARKI"
                      />
                    </div>
                    <div className="team-description">
                      <div className="team-info">
                        <h5>
                          <a href="team-single.html">Anis TABARKI</a>
                        </h5>
                      </div>
                      <div className="team-contact">
                        <span className="call fa fa-phone">
                          {" "}
                          <a href="tel:21628505129">(+216)28 505 129</a>
                        </span>

                        <span className="email">
                          {" "}
                          <i className="fa fa-envelope-o" />
                          <a href="mailto:Anis.TABARKI@sacemgroup.com">
                            {" "}
                            Anis.TABARKI@sacemgroup.com
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div
              className="entry-button"
              style={{ marginLeft: 20, marginTop: "40px" }}
            >
              <a className="button button-border" href="/contact">
                Nous Contacter
              </a>
            </div>
          </div>
        </section>
        {/*==============service===
         <section className="white-bg" style={{ paddingBottom: "80px" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 align-self-center">
                <div className="section-title">
                  <h2>SAV Sacem</h2>
                  <p>
                    Notre service après-vente est le partenaire incontournable
                    pour garantir la performance, la fiabilité et la longévité
                    des installations de distribution électrique. Grâce à notre
                    expertise et notre réactivité et efficience, nous aidons nos
                    clients à minimiser les risques de pannes et maximiser la
                    fiabilité de leurs investissements. Nous vous offrons des
                    contrats de maintenance personnalisés, adaptés aux
                    caractéristiques uniques de votre parc de transformateurs et
                    à vos besoins spécifiques.
                  </p>
                </div>
              </div>

              <div className="col-lg-6 sm-mt-30">
                <img
                  src="/assets/images/Products/SAV.png"
                  className="img-fluid full-width"
                  alt
                />
              </div>
            </div>
            <div className="entry-button" style={{ marginLeft: 20 }}>
              <a className="button button-border" href="/contact">
                Nous Contacter
              </a>
            </div>
          </div>
        </section>
        
        */}
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Sav;
