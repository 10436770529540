import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Footer from "./Footer";
import Loader from "./Loader";
import Header from "./Header";

const Transformateur_DIST_36 = () => {
  const StyledHeading = styled.h6`
    position: relative;
    font-size: 100px;
    color: #008bcd;

    &::before {
      content: "—";
      font-size: inherit;
      color: #008bcd;
      display: block;
    }
  `;
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        {/*=================================
page-title*/}
        <section
          className="page-title bg-overlay-black-60 parallax"
          style={{
            backgroundImage:
              "url(/assets/images/transformateur/Triphase/slider-triphase-36-24.png)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>SACEM INDUSTRIES</h1>
                  <p>innovative Technologies to Bring Electricity</p>
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/">
                      <i className="fa fa-home" /> Accueil
                    </a>{" "}
                    <i className="fa fa-angle-double-right" />
                  </li>
                  <li>
                    <span>
                      Transformateur de distribution Triphasé classe 36KV
                    </span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
page-title */}
        {/*=================================
 feature */}
        <section className="service white-bg page-section-ptb">
          <div className="container">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                className="row justify-content-center"
                style={{ width: "85%" }}
              >
                <div className="col-lg-8">
                  <div className="section-title text-center">
                    <h2 className="title-effect">
                      Transformateur de distribution Triphasé classe 36KV
                    </h2>
                  </div>
                </div>
              </div>
              <div className="row justify-content-end">
                <div className="entry-button" style={{ alignSelf: "center" }}>
                  <a
                    className="button button-border"
                    href="/transformateur_dist_24"
                  >
                    <div style={{ padding: "0.5rem" }}>classe 24KV</div>
                  </a>
                </div>
              </div>
            </div>
            {/* ============================================ */}
            {/*=====================================*/}
            {/**<section className="page-section-ptb"> */}
            <section>
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-sm-6 mb-30 ">
                    {/** */}
                    <div
                      className="rotateInDownLeft wow"
                      style={{
                        visibility: "visible",
                        animationName: "rotateInDownLeft",
                      }}
                    >
                      <div
                        className="media border p-4"
                        style={{ minHeight: "360px" }}
                      >
                        <div className="feature-icon media-icon mr-4">
                          <span className="ti-settings theme-color1" />
                        </div>
                        <div className="media-body">
                          <h5 className="text-back">
                            CARACTÉRISTIQUES GÉNÉRALES
                          </h5>
                          <br />
                          <p className="mb-0">
                            Transformateur triphasé immergé dans l’huile:
                          </p>
                          <br />
                          <ul className="list list-unstyled list-hand">
                            <li>Norme CEI 60076.</li>
                            <li>
                              Remplissage intégral, hermétique ou respirant.
                            </li>
                            <li>Refroidissement naturel type ONAN.</li>
                            <li>Installation intérieure/extérieure.</li>

                            <li>
                              Couleur: RAL7035 (autres couleurs sur demande).
                            </li>

                            <li>Protection contre la corrosion: C5H.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/** */}
                  </div>
                  <div className="col-lg-6 col-sm-6 mb-30 ">
                    {/** */}
                    <div
                      className="rotateInDownRight wow"
                      style={{
                        visibility: "visible",
                        animationName: "rotateInDownRight",
                      }}
                    >
                      <div
                        className="media border p-4"
                        style={{ minHeight: "360px" }}
                      >
                        <div className="feature-icon media-icon mr-4">
                          <span className="ti-bolt theme-color1" />
                        </div>
                        <div className="media-body">
                          <h5 className="text-back">TENSIONS DE SERVICES</h5>

                          <br />
                          <ul className="list list-unstyled list-hand">
                            <li>Classe:36KV</li>
                            <li>
                              Tension primaire: 30kV, 31.5kV, 33kV, 34.5kV.
                            </li>
                            <li>Couplages usuels selon CEI 60076-1.</li>
                            <li>Fréquence: 50Hz, 60Hz.</li>
                            <li>Conducteur: Cuivre (Aluminium en option).</li>
                            <li>
                              Diélectrique liquide: Huile Minérale selon CEI
                              60296.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/** */}
                  </div>
                  <div className="col-lg-6 col-sm-6 xs-mb-30">
                    {/** */}
                    <div
                      className="rotateInUpLeft wow"
                      style={{
                        visibility: "visible",
                        animationName: "rotateInUpLeft",
                      }}
                    >
                      <div
                        className="media border p-4"
                        style={{ minHeight: "550px" }}
                      >
                        <div className="feature-icon media-icon mr-4">
                          <span className="ti-package theme-color1" />
                        </div>
                        <div className="media-body">
                          <h5 className="text-back">ÉQUIPEMENTS DE BASE </h5>
                          <br />
                          <ul className="list list-unstyled list-hand">
                            <li>Anneau de levage</li>
                            <li>3 traversées MT en porcelaine</li>
                            <li>Plaque signalétique</li>
                            <li>Prises de mise à la terre</li>
                            <li>Commutateur de variation hors charge</li>
                            <li>4 traversées BT en porcelaine</li>
                            <li>Vanne de traitement</li>
                            <li>Vanne de vidange</li>
                            <li>Galets de roulement</li>
                            <li>Soupape de sureté</li>
                            <li>Orifice de remplissage</li>
                            <li>Doigt de gant</li>
                          </ul>

                          <p className="mb-0" style={{ fontWeight: "bold" }}>
                            Note : les options ci-dessus ne sont pas
                            exhaustives.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/** */}
                  </div>
                  <div className="col-lg-6 col-sm-6">
                    {/** */}
                    <div
                      className="rotateInUpRight wow"
                      style={{
                        visibility: "visible",
                        animationName: "rotateInUpRight",
                      }}
                    >
                      <div
                        className="media border p-4"
                        style={{ minHeight: "550px" }}
                      >
                        <div className="feature-icon media-icon mr-4">
                          <span className="ti-list theme-color1" />
                        </div>
                        <div className="media-body">
                          <h5 className="text-back">ÉQUIPEMENTS OPTIONNELS</h5>
                          <br />
                          <ul className="list list-unstyled list-hand">
                            <li>Bornes embrochables MT.</li>
                            <li>
                              Connecteurs embrochables MT en équerre ou droit.
                            </li>
                            <li>Parafoudres.</li>
                            <li>
                              Système de verrouillage des Bornes embrochables.
                            </li>
                            <li>Capots de protection.</li>
                            <li>Dispositif de protection (DMCR ou DGPT2).</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/** */}
                  </div>
                </div>
              </div>
              <div>
                {/*=================================
 Tableau */}
                <section id="tab36" className="page-section-ptb data-table">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <h3 style={{ color: "#00356a" }}>
                          CARACTERISTIQUES ELECTRIQUES{" "}
                        </h3>
                        <StyledHeading className="h6-line"></StyledHeading>
                        <br />
                        <div className="table-responsive">
                          <table className="table table-1 table-bordered table-striped">
                            <thead>
                              <tr>
                                <td colSpan={3}>
                                  Puissance {"{"}kVA{"}"}
                                </td>
                                <td>50</td>
                                <td>100</td>
                                <td>160</td>
                                <td>200</td>
                                <td>250</td>
                                <td>315</td>
                                <td>400</td>
                                <td>500</td>
                                <td>630</td>
                                <td>800</td>
                                <td>1000</td>
                                <td>1250</td>
                                <td>1600</td>
                                <td>2000</td>
                                <td>2500</td>
                                <td>3150</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td colSpan={3}>
                                  Pertes à vide {"{"}W{"}"}
                                </td>
                                <td>260</td>
                                <td>400</td>
                                <td>530</td>
                                <td>600</td>
                                <td>750</td>
                                <td>920</td>
                                <td>1160</td>
                                <td>1300</td>
                                <td>1600</td>
                                <td>1800</td>
                                <td>1900</td>
                                <td>2500</td>
                                <td>2900</td>
                                <td>3430</td>
                                <td>3870</td>
                                <td>4600</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>
                                  Pertes en charge {"{"}W{"}"}
                                </td>
                                <td>1450</td>
                                <td>2340</td>
                                <td>3330</td>
                                <td>3980</td>
                                <td>4230</td>
                                <td>5200</td>
                                <td>6210</td>
                                <td>7400</td>
                                <td>8820</td>
                                <td>12500</td>
                                <td>14000</td>
                                <td>18500</td>
                                <td>21500</td>
                                <td>27300</td>
                                <td>30500</td>
                                <td>36800</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>
                                  Courant à vide {"{"}%{"}"}
                                </td>
                                <td>5</td>
                                <td>4.40</td>
                                <td>3.90</td>
                                <td>3.70</td>
                                <td>3.50</td>
                                <td>3.40</td>
                                <td>3.20</td>
                                <td>3.10</td>
                                <td>2.90</td>
                                <td>2.80</td>
                                <td>2.70</td>
                                <td>2.50</td>
                                <td>2.20</td>
                                <td>2.10</td>
                                <td>1.90</td>
                                <td>1.80</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>
                                  Impedance de court circuit {"{"}%{"}"}
                                </td>
                                <td>4.50</td>
                                <td>4.50</td>
                                <td>4.50</td>
                                <td>4.50</td>
                                <td>4.50</td>
                                <td>4.50</td>
                                <td>4.50</td>
                                <td>4.50</td>
                                <td>4.50</td>
                                <td>5.00</td>
                                <td>5.50</td>
                                <td>6.00</td>
                                <td>6.50</td>
                                <td>7.00</td>
                                <td>7.50</td>
                                <td>7.50</td>
                              </tr>
                              <tr>
                                <td>
                                  Chute de tension {"{"}%{"}"}
                                </td>
                                <td colSpan={2}>Cos φ= 0.8</td>
                                <td>4.36</td>
                                <td>4.15</td>
                                <td>4.04</td>
                                <td>3.99</td>
                                <td>3.84</td>
                                <td>3.82</td>
                                <td>3.76</td>
                                <td>3.72</td>
                                <td>3.67</td>
                                <td>4.09</td>
                                <td>4.30</td>
                                <td>4.66</td>
                                <td>4.88</td>
                                <td>5.20</td>
                                <td>5.41</td>
                                <td>5.37</td>
                              </tr>
                              <tr>
                                <td>
                                  Chute de tension {"{"}%{"}"}
                                </td>
                                <td colSpan={2}>Cos φ= 1</td>
                                <td>2.82</td>
                                <td>2.29</td>
                                <td>2.04</td>
                                <td>1.95</td>
                                <td>1.66</td>
                                <td>1.62</td>
                                <td>1.53</td>
                                <td>1.46</td>
                                <td>1.38</td>
                                <td>1.54</td>
                                <td>1.38</td>
                                <td>1.46</td>
                                <td>1.33</td>
                                <td>1.35</td>
                                <td>1.21</td>
                                <td>1.15</td>
                              </tr>
                              <tr>
                                <td>
                                  Rendement {"{"}%{"}"} <br /> (à Cos φ=0.8)
                                </td>
                                <td>Charge en %</td>
                                <td>50</td>
                                <td>96.98</td>
                                <td>97.60</td>
                                <td>97.92</td>
                                <td>98.05</td>
                                <td>98.22</td>
                                <td>98.27</td>
                                <td>98.33</td>
                                <td>98.45</td>
                                <td>98.51</td>
                                <td>98.48</td>
                                <td>98.67</td>
                                <td>98.60</td>
                                <td>98.72</td>
                                <td>98.73</td>
                                <td>98.86</td>
                                <td>98.92</td>
                              </tr>
                              <tr>
                                <td>
                                  Rendement {"{"}%{"}"} <br /> (à Cos φ=0.8)
                                </td>
                                <td>Charge en %</td>
                                <td>75</td>
                                <td>96.54</td>
                                <td>97.22</td>
                                <td>97.56</td>
                                <td>97.69</td>
                                <td>97.96</td>
                                <td>98.01</td>
                                <td>98.10</td>
                                <td>98.21</td>
                                <td>98.29</td>
                                <td>98.19</td>
                                <td>98.40</td>
                                <td>98.31</td>
                                <td>98.46</td>
                                <td>98.46</td>
                                <td>98.62</td>
                                <td>98.68</td>
                              </tr>
                              <tr>
                                <td>
                                  Rendement {"{"}%{"}"} <br /> (à Cos φ=0.8)
                                </td>
                                <td>Charge en %</td>
                                <td>100</td>
                                <td>95.9</td>
                                <td>96.69</td>
                                <td>97.07</td>
                                <td>97.22</td>
                                <td>97.57</td>
                                <td>97.63</td>
                                <td>97.75</td>
                                <td>97.87</td>
                                <td>97.97</td>
                                <td>97.81</td>
                                <td>98.05</td>
                                <td>97.94</td>
                                <td>98.13</td>
                                <td>98.12</td>
                                <td>98.31</td>
                                <td>98.38</td>
                              </tr>
                            </tbody>
                          </table>
                          {/*=================================
 Tableau */}
                          <br />
                          <br />
                          <br />
                          <h3 style={{ color: "#00356a" }}>
                            DIMENSIONS ET POIDS
                          </h3>
                          <StyledHeading className="h6-line"></StyledHeading>
                          <br />
                          <table className="table table-1 table-bordered table-striped">
                            <thead>
                              <tr>
                                <td colSpan={3}>Puissance (kVA)</td>
                                <td>50</td>
                                <td>100</td>
                                <td>160</td>
                                <td>200</td>
                                <td>250</td>
                                <td>315</td>
                                <td>400</td>
                                <td>500</td>
                                <td>630</td>
                                <td>800</td>
                                <td>1000</td>
                                <td>1250</td>
                                <td>1600</td>
                                <td>2000</td>
                                <td>2500</td>
                                <td>3150</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td colSpan={3}>L</td>
                                <td>950</td>
                                <td>1100</td>
                                <td>1150</td>
                                <td>1200</td>
                                <td>1290</td>
                                <td>1400</td>
                                <td>1380</td>
                                <td>1420</td>
                                <td>1600</td>
                                <td>1750</td>
                                <td>1900</td>
                                <td>1950</td>
                                <td>2130</td>
                                <td>2450</td>
                                <td>2680</td>
                                <td>2900</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>I</td>
                                <td>700</td>
                                <td>720</td>
                                <td>840</td>
                                <td>850</td>
                                <td>860</td>
                                <td>950</td>
                                <td>880</td>
                                <td>920</td>
                                <td>1020</td>
                                <td>1100</td>
                                <td>1170</td>
                                <td>1200</td>
                                <td>1450</td>
                                <td>1400</td>
                                <td>1520</td>
                                <td>1560</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>H</td>
                                <td>1290</td>
                                <td>1250</td>
                                <td>1370</td>
                                <td>1390</td>
                                <td>1400</td>
                                <td>1430</td>
                                <td>1500</td>
                                <td>1550</td>
                                <td>1580</td>
                                <td>1750</td>
                                <td>2000</td>
                                <td>2000</td>
                                <td>1900</td>
                                <td>2230</td>
                                <td>2620</td>
                                <td>2800</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>E</td>
                                <td>520</td>
                                <td>520</td>
                                <td>520</td>
                                <td>520</td>
                                <td>520</td>
                                <td>670</td>
                                <td>670</td>
                                <td>670</td>
                                <td>670</td>
                                <td>670</td>
                                <td>820</td>
                                <td>820</td>
                                <td>820</td>
                                <td>820</td>
                                <td>820</td>
                                <td>1000</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>Poids Huile (Kg)</td>
                                <td>130</td>
                                <td>165</td>
                                <td>200</td>
                                <td>220</td>
                                <td>240</td>
                                <td>280</td>
                                <td>320</td>
                                <td>350</td>
                                <td>440</td>
                                <td>550</td>
                                <td>630</td>
                                <td>740</td>
                                <td>1010</td>
                                <td>1360</td>
                                <td>1650</td>
                                <td>2000</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>Poids Total (kg)</td>
                                <td>550</td>
                                <td>640</td>
                                <td>770</td>
                                <td>1030</td>
                                <td>1060</td>
                                <td>1260</td>
                                <td>1460</td>
                                <td>1590</td>
                                <td>1950</td>
                                <td>2450</td>
                                <td>2900</td>
                                <td>3400</td>
                                <td>4400</td>
                                <td>5950</td>
                                <td>7040</td>
                                <td>8850</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="entry-button" style={{ marginLeft: 20 }}>
                      <a className="button button-border" href="/contact">
                        Nous Contacter
                      </a>
                    </div>
                    <div className="entry-button" style={{ marginLeft: 20 }}>
                      <a
                        className="button button-border"
                        href="/assets/brochures/catalogue transformateurs 2024 source.pdf"
                      >
                        Catalogue
                      </a>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </section>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Transformateur_DIST_36;
