import React, { useEffect, useState } from "react";
import Loader from "../Loader";
import Footer_en from "./Footer_en";
import Header_en from "./Header_en";

const Faq_en = () => {
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    //switch language to english
    document.documentElement.lang = "en";
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header_en />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        {/*=================================
page-title*/}

        {/*=================================
page-title */}
        {/*=================================
 faq*/}
        <section
          className="faq white-bg page-section-ptb"
          style={{ paddingTop: "170px" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="section-title text-center">
                  <h6></h6>
                  <h2 className="title-effect">Frequently asked questions</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="accordion shadow">
                  <div className="acd-group acd-active">
                    <a href="#" className="acd-heading">
                      WHAT IS A TRANSFORMER?
                    </a>
                    <div className="acd-des">
                      <p>
                        A transformer is an electrical device (static machine)
                        which,by electromagnetic induction,transforms electrical
                        energy from one or more circuits to one or more other
                        circuits at the same frequency. By varying the magnetic
                        relations or values of the input to the output, a
                        transformer produces modified values of voltage and
                        current. A transformer operates by having the input
                        windings wound around a special steel core which
                        transmits the alternating current pulses to the output
                        windings.
                      </p>
                    </div>
                  </div>
                  <div className="acd-group">
                    <a href="#" className="acd-heading">
                      HOW TO SPECIFY A TRANSFORMER?
                    </a>
                    <div className="acd-des">
                      <ul className="list list-unstyled list-mark">
                        <li>Single-phase or three-phase</li>
                        <li>Primary voltage(s) and connection (e.g., delta)</li>
                        <li>
                          Secondary voltage(s) and connection (e.g., star)
                        </li>
                        <li>Power in kVA</li>
                        <li>Frequency (e.g., 60 Hz or 50/60 Hz)</li>
                        <li>Transformer type: hermetic or oil-preserving</li>
                        <li>Operating conditions</li>
                        <li>
                          Temperature rise (limitations, if any, are important)
                        </li>
                        <li>
                          Transformer use and type (application description)
                        </li>
                        <li>No-load secondary voltage</li>
                        <li>Regulation percentage</li>
                        <li>Impedance percentage</li>
                        <li>Dimensional restrictions</li>
                        <li>Watt losses and limits</li>
                        <li>
                          Type of bushings (porcelain or plug-in bushings)
                        </li>
                        <li>Mounting dimensions</li>
                      </ul>
                    </div>
                  </div>
                  <div className="acd-group">
                    <a href="#" className="acd-heading">
                      TRANSFORMER VOLTAGE REGULATION
                    </a>
                    <div className="acd-des">
                      <p>
                        Voltage regulation in transformers is the difference
                        between no-load voltage and full-load voltage. It is
                        usually expressed as a percentage: for example, with a
                        transformer supplying 400 volts at no load and 380 volts
                        at full load, the regulation would be 5%. The medium
                        voltage can also be lower or higher than the rated
                        voltage, and the primary winding is then adjusted by
                        moving the tap changer position. Electrical and lighting
                        transformers generally have a regulation of 2.5% to 5%,
                        depending on size and application.
                      </p>
                    </div>
                  </div>
                  <div className="acd-group">
                    <a href="#" className="acd-heading">
                      PARALLEL TRANSFORMERS
                    </a>
                    <div className="acd-des">
                      <p>
                        Transformers can be paralleled when their voltages are
                        equal. If the voltages are not equal, the difference
                        between the voltages will result in a net voltage which
                        causes a current to flow on the closed network between
                        the two transformers. This will lead to false charging
                        and, if there is enough difference between the two
                        voltages, the transformers, being connected to each
                        other, can effectively burn out without any payload. In
                        order to have transformers with voltage distribution and
                        proportional load, their impedances must be similar. In
                        most commercial installations, a tolerance of 10% in
                        impedance is permitted when transformers are connected
                        in parallel. Three-phase transformers must have the same
                        connection and similar angular displacement, which means
                        that the phasing must be the same on each transformer.
                      </p>
                    </div>
                  </div>
                  <div className="acd-group">
                    <a href="#" className="acd-heading">
                      HOW IS IMPEDANCE APPLIED TO TRANSFORMERS?
                    </a>
                    <div className="acd-des">
                      <p>
                        Impedance is the resistance found in all circuits where
                        alternating current is used. Electrically, impedance is
                        made up of three components: DC resistance, inductance
                        and capacitance. Inductance and capacitance can be added
                        directly when converted to desired units. One is
                        positive and the other is negative. The result of this
                        quantity is then vectorially added to the resistance.
                        The impedance of most ordinary transformers circulates
                        between 3% and 6%. Very low impedance transformers tend
                        to give better regulation. If the transformer's
                        secondary were to be accidentally short-circuited, and
                        the transformer's impedance is low, there would be a
                        very high current flow on both the primary and secondary
                        windings. This can cause mechanical displacement of the
                        windings and a short-circuit between adjacent turns.
                        With a transformer having an impedance of 5% and a
                        short-circuited secondary, the primary and secondary
                        current would automatically be limited to 20 times the
                        normal current. If the impedance were 10% of the primary
                        current, the secondary would be limited to 10 times the
                        normal current, and so on, within certain limits that
                        cannot be explained here.
                      </p>
                    </div>
                  </div>
                  <div className="acd-group">
                    <a href="#" className="acd-heading">
                      WHAT DOES ONAN MEAN?
                    </a>

                    <div className="acd-des">
                      <p>
                        The immersed transformer is usually cooled with its
                        dielectric fluid, which is usually mineral oil. ONAN is
                        a cooling mode that stands for huile naturelle Air
                        naturel (natural circulation of oil inside the
                        transformer and air outside the transformer to dissipate
                        the thermal energy produced by the transformer. Other
                        cooling modes are: ONAF (natural oil forced air); OFAF
                        (forced oil forced air); ODAF (directed oil (pumped)
                        forced air).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 text-center mt-40">
                <h6>
                  If you can't find the answer to your question in our FAQ, you
                  can always contact us directly at .
                </h6>
                <br />
                <h6>
                  <a
                    className="theme-color"
                    href="mailto:contact@sacemgroup.com"
                  >
                    {" "}
                    contact@sacemgroup.com
                  </a>{" "}
                </h6>
                <br />
                <div
                  className="row"
                  style={{
                    justifyContent: "center",
                    paddingRight: "15px",
                  }}
                >
                  <a href="/en/contact" className="button button-border">
                    CONTACT US
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
 careers*/}
      </div>
      <div>
        <Footer_en />
      </div>
    </div>
  );
};

export default Faq_en;
