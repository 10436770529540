import React, { useEffect, useState } from "react";
import NosAdresses_en from "./NosAdresses_en";
import NosCommerciaux_en from "./NosCommerciaux_en";
import Loader from "../Loader";
import Footer_en from "./Footer_en";
import Header_en from "./Header_en";
import Form_en from "./Form_en";

const Nouscontacter_en = () => {
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    //switch language to english
    document.documentElement.lang = "en";
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header_en />
      </div>
      <Loader isVisible={isVisible} />
      <div style={{ paddingTop: "120px" }}>
        {/*=================================
page-title*/}

        {/*=================================
Teams */}
        <section className="page-section-ptb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-title text-center">
                  <h2 className="title-effect">
                    Our Business Development Experts
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div style={{ width: "1170px", paddingBottom: "40px" }}>
                <NosCommerciaux_en />
              </div>

              {/*=================================Form */}
              <div
                style={{
                  width: "1170px",
                  paddingTop: "40px",
                  paddingBottom: "20px",

                  border: "2px solid #eeeeee",
                }}
              >
                <Form_en />
              </div>

              {/*=================================Maps */}
              <div
                style={{
                  width: "1170px",
                  paddingTop: "40px",
                }}
              >
                <NosAdresses_en />
              </div>

              {/*=================================Form */}
            </div>
          </div>
        </section>
      </div>
      <div>
        <Footer_en />
      </div>
    </div>
  );
};

export default Nouscontacter_en;
