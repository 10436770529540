import React, { useEffect, useState } from "react";
import Certifs_Iso from "./Certifs_Iso";
import Certifs_Cesi from "./Certifs_Cesi";
import Certifs_Veiki_Vnl from "./Certifs_Veiki_Vnl";
import Cerrtifs_Kema from "./Cerrtifs_Kema";
import Loader from "./Loader";
import Header from "./Header";
import Footer from "./Footer";

const Certificats = () => {
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        <section
          className="page-title bg-overlay-black-60 parallax"
          style={{ backgroundImage: "url(/assets/images/Bannier/certif.png)" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>Certificats</h1>
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/">
                      <i className="fa fa-home" /> Accueil
                    </a>{" "}
                    <i className="fa fa-angle-double-right" />
                  </li>

                  <li>
                    <span>Certificats</span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="shop-tab page-section-ptb">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="tab tab-border nav-center">
                  <ul className="nav nav-tabs" id="productsTab" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active show"
                        id="kema-tab"
                        data-toggle="tab"
                        href="#kema"
                        role="tab"
                        aria-controls="kema"
                        aria-selected="true"
                      >
                        {/**<img src="/assets/images/Certificat/kema.png" alt="" /> */}{" "}
                        ISO
                      </a>
                    </li>
                    {/** */}
                    <li className="nav-item">
                      <a
                        className="nav-link "
                        id="veikivnl-tab"
                        data-toggle="tab"
                        href="#veikivnl"
                        role="tab"
                        aria-controls="veikivnl"
                        aria-selected="true"
                      >
                        {" "}
                        VEIKI-VNL
                      </a>
                    </li>
                    {/** */}
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="cesi-tab"
                        data-toggle="tab"
                        href="#cesi"
                        role="tab"
                        aria-controls="cesi"
                        aria-selected="false"
                      >
                        CESI
                      </a>
                    </li>
                    {/** */}
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        // id="cr-tab"
                        id="iso-tab"
                        data-toggle="tab"
                        href="#iso"
                        role="tab"
                        aria-controls="iso"
                        aria-selected="false"
                      >
                        KEMA
                      </a>
                    </li>
                  </ul>
                  {/** */}
                  <div className="tab-content" id="productsTabContent">
                    {/** */}
                    <div
                      className="tab-pane fade active show"
                      id="kema"
                      role="tabpanel"
                      aria-labelledby="kema-tab"
                    >
                      <Certifs_Iso />
                    </div>
                    {/** */}
                    <div
                      className="tab-pane fade"
                      id="veikivnl"
                      role="tabpanel"
                      aria-labelledby="veikivnl-tab"
                    >
                      <Certifs_Veiki_Vnl />
                    </div>
                    {/** */}
                    <div
                      className="tab-pane fade"
                      id="cesi"
                      role="tabpanel"
                      aria-labelledby="cesi-tab"
                    >
                      <Certifs_Cesi />
                    </div>
                    {/** */}
                    <div
                      className="tab-pane fade"
                      id="iso"
                      role="tabpanel"
                      aria-labelledby="iso-tab"
                    >
                      <Cerrtifs_Kema />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Certificats;
