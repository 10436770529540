import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Loader from "./Loader";
import Header from "./Header";
import Form_carriers from "./Form_carriers";
import Fortestuse from "./Fortestuse";

const Rhcarriers = () => {
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        {/*=================================
  page-title*/}

        {/*=================================
  page-title */}
        {/*=================================
   careers*/}
        <section
          className="careers white-bg page-section-ptb"
          style={{ paddingTop: "170px" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="section-title text-center">
                  <h6></h6>
                  <h2 className="title-effect">
                    Trouvez le poste fait pour vous et postulez !
                  </h2>
                  <p>
                    Chez SACEM Industries, nous croyons que notre succès repose
                    sur trois valeurs fondamentales qui guident nos actions au
                    quotidien
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <h4>COLLABORATION</h4>
                <p>
                  Notre force réside dans le travail d'équipe. Nous encourageons
                  le partage des connaissances et l'entraide entre nos 450
                  collaborateurs. Chaque membre de notre équipe contribue à
                  notre réussite collective.
                </p>
                <h4 className="mt-30">RESPECT </h4>
                <p>
                  Le respect est au cœur de nos relations, tant en interne
                  qu'avec nos clients et partenaires. Nous valorisons la
                  diversité des talents, des perspectives et des expériences,
                  créant ainsi un environnement de travail inclusif où chacun
                  peut s'épanouir.
                </p>
                <h4 className="mt-30">INNOVATION </h4>
                <p>
                  Depuis 1966, l'innovation est notre moteur. Nous investissons
                  dans la formation continue de nos équipes et encourageons la
                  créativité pour développer des solutions énergétiques toujours
                  plus performantes. Rejoindre SACEM Industries, c'est
                  participer à la construction de l'avenir du secteur
                  électrique.
                </p>
              </div>
              <div className="col-lg-6 sm-mt-30">
                <div>
                  <img
                    className="img-fluid full-width"
                    src="/assets/images/Entreprise/P2.png"
                    alt="Transformateur SACEM"
                    style={{ height: "416px", width: "780px" }}
                  />
                </div>
              </div>
            </div>
            <br />
            <p>
              Vous partagez nos valeurs ? Rejoignez une équipe dynamique où
              votre talent et votre potentiel seront valorisés. Ensemble,
              continuons à illuminer le monde avec excellence et passion.
            </p>
            <div>
              {/**div of offers */}
              <div>
                {/** */}

                {/** 
                <div className="accordion shadow">
                  <div className="acd-group acd-active">
                    <a className="acd-heading" href="#/">
                      Ingénieur études
                    </a>
                    <div className="acd-des">
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <span className="mb-10">
                            <b>Localisation : </b>Tunisie{" "}
                          </span>
                          <span>
                            <b>Département : </b>Etudes{" "}
                          </span>
                          <p className="mt-20"></p>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <h4 className="mb-20 theme-color">
                            Description de l'emploi
                          </h4>
                          <ul className="list list-unstyled mb-30">
                            <li>
                              <i className="fa fa-angle-right" />
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <h4 className="mb-20 theme-color">
                            Ce que nous offrons
                          </h4>
                          <ul className="list list-unstyled mb-30">
                            <li>
                              <i className="fa fa-angle-right" />
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                         
                          <p className="mt-20 mb-20">
                            Les candidats intéressés et éligibles sont invités à
                            envoyer leur CV accompagné de leurs informations
                            personnelles à l'adresse e-mail suivante:{" "}
                            <a href="mailto:contact@sacemgroup.com">
                              contact@sacemgroup.com
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                */}
                {/** 
                <div className="accordion shadow">
                  <div className="acd-group acd-active">
                    <a className="acd-heading" href="#/">
                      Ingénieur Méthodes
                    </a>
                    <div className="acd-des">
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <span className="mb-10">
                            <b>Localisation : </b>Tunisie{" "}
                          </span>
                          <span>
                            <b>Département : </b>Etudes{" "}
                          </span>
                          <p className="mt-20"></p>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <h4 className="mb-20 theme-color">
                            Description de l'emploi
                          </h4>
                          <ul className="list list-unstyled mb-30">
                            <li>
                              <i className="fa fa-angle-right" />
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <h4 className="mb-20 theme-color">
                            Ce que nous offrons
                          </h4>
                          <ul className="list list-unstyled mb-30">
                            <li>
                              <i className="fa fa-angle-right" />
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <p className="mt-20 mb-20">
                            Les candidats intéressés et éligibles sont invités à
                            envoyer leur CV accompagné de leurs informations
                            personnelles à l'adresse e-mail suivante:{" "}
                            <a href="mailto:contact@sacemgroup.com">
                              contact@sacemgroup.com
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                */}
                {/** 
                <div className="accordion shadow">
                  <div className="acd-group acd-active">
                    <a className="acd-heading" href="#/">
                      Ingénieur Qualité
                    </a>
                    <div className="acd-des">
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <span className="mb-10">
                            <b>Localisation : </b>Tunisie{" "}
                          </span>
                          <span>
                            <b>Département : </b>Etudes{" "}
                          </span>
                          <p className="mt-20"></p>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <h4 className="mb-20 theme-color">
                            Description de l'emploi
                          </h4>
                          <ul className="list list-unstyled mb-30">
                            <li>
                              <i className="fa fa-angle-right" />
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <h4 className="mb-20 theme-color">
                            Ce que nous offrons
                          </h4>
                          <ul className="list list-unstyled mb-30">
                            <li>
                              <i className="fa fa-angle-right" />
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <p className="mt-20 mb-20">
                            Les candidats intéressés et éligibles sont invités à
                            envoyer leur CV accompagné de leurs informations
                            personnelles à l'adresse e-mail suivante:{" "}
                            <a href="mailto:contact@sacemgroup.com">
                              contact@sacemgroup.com
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                */}
                {/** */}
              </div>
              <div style={{ paddingTop: "20px" }}>
                <Form_carriers />
              </div>
            </div>
          </div>
        </section>
        {/*=================================
   careers*/}
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Rhcarriers;
