import React, { useEffect, useState } from "react";
import Form_carriers_en from "./Form_carriers_en";
import Loader from "../Loader";
import Footer_en from "./Footer_en";
import Header_en from "./Header_en";

const Pfebook_en = () => {
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header_en />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        {/*=================================
page-title*/}

        {/*=================================
page-title */}
        {/*=================================
 careers*/}
        <section
          className="careers white-bg page-section-ptb"
          style={{ paddingTop: "170px" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="section-title text-center">
                  <h6></h6>
                  <h2 className="title-effect">PFE Project</h2>
                  <p className></p>
                </div>
              </div>
            </div>
            <div>
              <div>
                {/** */}
                <div className="accordion shadow">
                  <div className="acd-group acd-active">
                    <a className="acd-heading" href="#">
                      PFE-01: TRANSFORMER SIZING APPLICATION
                    </a>
                    <div className="acd-des">
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <span>
                            <b>Department : </b>IT{" "}
                          </span>
                          <span>
                            <b>Objective : </b>The main aim of the project is to
                            develop an application capable of sizing electrical
                            transformers while optimizing the associated costs.
                            This application aims to be an intuitive tool for
                            engineers, facilitating technical decision-making.
                          </span>
                          <p className="mt-20" />
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <h4 className="mb-20 theme-color">
                            Application objectives
                          </h4>
                          <ul className="list list-unstyled mb-30">
                            <li>
                              <i className="fa fa-angle-right" /> Design an
                              application capable of:
                            </li>
                            <li>
                              -Dimension a transformer according to the
                              parameters supplied (power, voltage, frequency,
                              etc.).
                            </li>
                            <li>-Calculate losses and energy efficiency.</li>
                            <li>
                              -Optimize costs, including purchase, installation
                              and operating costs
                            </li>
                            <li>
                              <i className="fa fa-angle-right" /> Provide an
                              intuitive, ergonomic user interface.
                            </li>
                            <li>
                              <i className="fa fa-angle-right" /> Integrate
                              simulation functions to compare different
                              configurations
                            </li>
                            <li>
                              <i className="fa fa-angle-right" />
                              Generate automated reports.
                            </li>
                            <li>
                              <i className="fa fa-angle-right" /> Generate
                              manufacturing plans for finished seedlings.
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <h4 className="mb-20 theme-color">Functional part</h4>
                          <ul className="list list-unstyled mb-30">
                            <li>
                              <i className="fa fa-angle-right" /> Enter the
                              transformer's technical parameters (power, primary
                              and secondary voltage, type of use, etc.).
                            </li>
                            <li>
                              <i className="fa fa-angle-right" />
                              Automatic dimensioning according to standards and
                              constraints.
                            </li>
                            <li>
                              <i className="fa fa-angle-right" /> Calculation of
                              investment and operating costs.
                            </li>
                            <li>
                              <i className="fa fa-angle-right" />
                              Comparison of several scenarios
                            </li>
                            <li>
                              <i className="fa fa-angle-right" /> Suggest
                              optimal alternatives to reduce costs.
                            </li>
                            <li>
                              <i className="fa fa-angle-right" /> Analysis of
                              losses (iron, copper, partial load, etc.).
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <p className="mt-20 mb-20">
                            Interested candidates are invited to send their CVs
                            to the following e-mail address:
                            <a href="mailto:recrutement@sacemgroup.com">
                              recrutement@sacemgroup.com
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="acd-group">
                    <a className="acd-heading" href="#">
                      PFE-02: TRANSFORMER COSTING
                    </a>
                    <div className="acd-des">
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <span>
                            <b>Department: </b>IT{" "}
                          </span>
                          <span>
                            <b>Objective: </b>The project is to develop an
                            application for calculating transformer costs. This
                            application will connect to Navision to retrieve
                            BOMs and related items, and generate a printout of
                            the calculated costs.
                          </span>
                          <p className="mt-20" />
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <h4 className="mb-20 theme-color">
                            Application objectives
                          </h4>
                          <ul className="list list-unstyled mb-30">
                            <li>
                              <i className="fa fa-angle-right" /> Retrieve item
                              and BOM data and customers from Navision.
                            </li>
                            <li>
                              <i className="fa fa-angle-right" /> Automatically
                              calculate processor costs based on BOMs and
                              production parameters.
                            </li>
                            <li>
                              <i className="fa fa-angle-right" /> Generate
                              detailed, printable and exportable cost reports.
                            </li>
                            <li>
                              <i className="fa fa-angle-right" /> Provide a
                              backend interface for configuring the application:
                              management of items, loads, users and general
                              parameters.
                            </li>
                            <li>
                              <i className="fa fa-angle-right" /> Integrate an
                              artificial intelligence (AI) component to provide
                              predictive analytics, recommendations and cost
                              optimization.
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <h4 className="mb-20 theme-color">Functional part</h4>
                          <ul className="list list-unstyled mb-30">
                            <li>
                              <i className="fa fa-angle-right" /> Secure
                              authentication with role management
                              (administrator, standard user, etc.).
                            </li>
                            <li>
                              <i className="fa fa-angle-right" /> Connection to
                              Navision via API REST or SOAP.
                            </li>
                            <li>
                              <i className="fa fa-angle-right" />
                              Synchronization of items: prices, dimensions,
                              stocks, etc.
                            </li>
                            <li>
                              <i className="fa fa-angle-right" /> Retrieve BOMs
                              associated with transformers.
                            </li>
                            <li>
                              <i className="fa fa-angle-right" /> Add and manage
                              fixed and variable costs (labor, transport,
                              energy, etc.).
                            </li>
                            <li>
                              <i className="fa fa-angle-right" /> Set
                              coefficients or rules for load calculations.
                            </li>
                            <li>
                              <i className="fa fa-angle-right" /> Automatic cost
                              calculation based on:(Selected BOM,Item prices
                              retrieved from Navision or configured in the
                              backend,Additional charges configured in the
                              backend).
                            </li>
                            <li>
                              <i className="fa fa-angle-right" />
                              Ability to add manual adjustments (margins,
                              discounts, etc.)
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <p className="mt-20 mb-20">
                            Interested candidates are invited to send their CVs
                            to the following e-mail address:
                          </p>
                          <a href="mailto:recrutement@sacemgroup.com">
                            recrutement@sacemgroup.com
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/** */}

                {/** */}

                {/** */}
              </div>
              <Form_carriers_en />
            </div>
          </div>
        </section>
        {/*=================================
 careers*/}
      </div>
      <div>
        <Footer_en />
      </div>
    </div>
  );
};

export default Pfebook_en;
