import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Loader from "../Loader";
import Footer_en from "./Footer_en";
import Header_en from "./Header_en";
const Power_en = () => {
  const StyledHeading = styled.h6`
    position: relative;
    font-size: 100px;
    color: #008bcd;

    &::before {
      content: "—";
      font-size: inherit;
      color: #008bcd;
      display: block;
    }
  `;
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    //switch language to english
    document.documentElement.lang = "en";
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header_en />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        <section
          className="page-title bg-overlay-black-60 parallax"
          style={{
            backgroundImage: "url(/assets/images/Power/bannier-HTA.png)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>SACEM INDUSTRIES</h1>
                  <p>Innovative Technologies to Bring Electricity</p>
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/en">
                      <i className="fa fa-home"></i> Home
                    </a>{" "}
                    <i className="fa fa-angle-double-right"></i>
                  </li>
                  <li>
                    <span>Power Systems</span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/*=================================
page-title */}
        {/*=================================
feature */}
        <section className="service white-bg page-section-ptb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-title text-center">
                  <h2 className="title-effect">
                    Our Power Systems product ranges
                  </h2>
                </div>
              </div>
            </div>
            {/* ============================================ */}
            <div className="service-3">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  {/** */}
                  <div
                    className="section-title slideInLeft wow"
                    style={{
                      visibility: "visible",
                      animationName: "slideInLeft",
                    }}
                  >
                    <div
                      className="service-blog text-left"
                      style={{ marginBottom: "50px" }}
                    >
                      <h3 className="theme-color">AIS-Panel</h3>
                      <StyledHeading className="h6-line"></StyledHeading>
                      <p>
                        Our modular 36 kV medium-voltage cubicles, fitted with
                        SF6 switches and busbars in air, are manufactured to are
                        manufactured according to the international
                        IEC-62271-200 standard. Approved by STEG and already
                        installed in the Tunisian network.
                      </p>
                      <ul
                        className="list list-unstyled list-hand"
                        style={{ borderRight: "0px solid white" }}
                      >
                        <li>SME01 : Network cell (Arrival/Departure).</li>
                        <li>SME02 : Transformer protection cell with fuse.</li>
                        <li>SME03 : Voltage measurement cell.</li>
                        <li>SME05 : Double circuit-breaker cubicle.</li>
                      </ul>
                    </div>

                    <div className="entry-button">
                      <a className="button arrow" href="/tableauais">
                        Read more
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </a>
                      <a
                        className="button button-border"
                        style={{ marginLeft: "250px" }}
                        href="/en/contact"
                      >
                        Contact us
                      </a>
                    </div>
                  </div>

                  {/** */}
                </div>
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  <div className="row mt-70">
                    <div
                      className="col-lg-12 col-md-12"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <img
                          className="img-fluid"
                          src="/assets/images/cellule trio.png"
                          alt="Tableau-AIS"
                          style={{ maxWidth: "80%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ============================================ */}
              <div className="row">
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  <div className="row mt-70">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img
                        className="img-fluid"
                        src="/assets/images/CP1T.png"
                        alt="Coffret Protection Transformateur"
                        style={{ maxWidth: "80%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  {/** */}
                  <div
                    className="slideInRight wow"
                    style={{
                      visibility: "visible",
                      animationName: "slideInRight",
                    }}
                  >
                    <div
                      className="service-blog left text-left"
                      style={{ marginBottom: "50px" }}
                    >
                      <h3 className="theme-color">
                        Transformer protection box
                      </h3>
                      <StyledHeading className="h6-line"></StyledHeading>
                      <p>
                        The transformer protection box is designed to monitoring
                        and protecting transformers by tripping the associated
                        upstream and downstream associated protection devices.
                      </p>
                      <ul
                        className="list list-unstyled list-hand"
                        style={{ borderLeft: "0px solid white" }}
                      >
                        <li>CP1T : One transformer protection box.</li>
                        <li>CP2T: Two-transformer protection box.</li>
                        <li>With DMCR and DGPT2 relays.</li>
                        <li>With BUCCHOLZ relay.</li>
                      </ul>
                    </div>
                    <div className="entry-button">
                      <a
                        className="button arrow"
                        href="/Coffret_Protection_Transformateur"
                      >
                        Read more
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </a>
                      <a
                        className="button button-border"
                        style={{ marginLeft: "250px" }}
                        href="/en/contact"
                      >
                        Contact us
                      </a>
                    </div>
                  </div>
                  {/** */}
                </div>
              </div>
              {/* ============================================ */}
              <div className="row">
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  {/** */}
                  <div
                    className="section-title slideInLeft wow"
                    style={{
                      visibility: "visible",
                      animationName: "slideInLeft",
                    }}
                  >
                    <div
                      className="service-blog text-left"
                      style={{ marginBottom: "50px" }}
                    >
                      <h3 className="theme-color">Energy Management System</h3>
                      <StyledHeading className="h6-line"></StyledHeading>
                      <p>
                        SEMS is an energy management system for real-time remote
                        supervision of MV/LV transformer substations. HV/LV
                        transformer substations, SEMS makes it possible to
                        measure, centralise data and events collected in the
                        substation the substation, and sending this information
                        remotely to a to a monitoring platform.
                      </p>
                    </div>
                    <div className="entry-button">
                      <a className="button arrow" href="/eems">
                        Read more
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </a>
                      <a
                        className="button button-border"
                        style={{ marginLeft: "250px" }}
                        href="/en/contact"
                      >
                        Contact us
                      </a>
                    </div>
                  </div>
                  {/** */}
                </div>
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  <div className="row mt-70">
                    <div className="col-lg-12 col-md-12">
                      <div>
                        <img
                          className="img-fluid"
                          src="/assets/images/Power/cofr4.png"
                          alt="Système de Gestion de l'Energie"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ============================================ */}
              <div className="row">
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  <div className="row mt-70">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img
                        className="img-fluid"
                        src="/assets/images/Power/filtre active2.png"
                        alt="Filtre Actif Anti-harmoniques"
                        style={{ maxWidth: "80%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  {/** */}
                  <div
                    className="slideInRight wow"
                    style={{
                      visibility: "visible",
                      animationName: "slideInRight",
                    }}
                  >
                    <div
                      className="service-blog left text-left"
                      style={{ marginBottom: "50px" }}
                    >
                      <h3 className="theme-color">
                        Filtre Actif Anti-harmoniques
                      </h3>
                      <StyledHeading className="h6-line"></StyledHeading>

                      <p>
                        Our active anti-harmonic filter is designed to eliminate
                        harmonics present in the electrical network,
                        guaranteeing a stable and reliable power supply. By
                        reducing distortion and stabilising power quality and
                        stabilising power quality, it protects the entire
                        installation and all connected equipment, thereby
                        limiting the risk of failure.{" "}
                      </p>
                    </div>
                    <div className="entry-button">
                      <a className="button arrow" href="/filtreactive">
                        Read more
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </a>
                      <a
                        className="button button-border"
                        style={{ marginLeft: "250px" }}
                        href="/en/contact"
                      >
                        Contact us
                      </a>
                    </div>
                  </div>
                  {/** */}
                </div>
              </div>
              {/* ============================================ */}
              <div className="row">
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  {/** */}
                  <div
                    className="section-title slideInLeft wow"
                    style={{
                      visibility: "visible",
                      animationName: "slideInLeft",
                    }}
                  >
                    <div
                      className="service-blog text-left"
                      style={{ marginBottom: "50px" }}
                    >
                      <h3 className="theme-color">Battery of Capacitors</h3>
                      <StyledHeading className="h6-line"></StyledHeading>
                      <p>
                        We offer a wide range of capacitor banks adapted to the
                        specific needs of energy energy optimisation and network
                        protection.
                      </p>
                      <ul
                        className="list list-unstyled list-hand"
                        style={{ borderRight: "0px solid white" }}
                      >
                        <li>Fixed compensation.</li>
                        <li>Automatic compensation.</li>
                        <li>Compensation for unpolluted networks.</li>
                        <li>Compensation for lightly polluted networks.</li>
                        <li>Compensation for polluted networks.</li>
                      </ul>
                    </div>
                    <div className="entry-button">
                      <a className="button arrow" href="/batterie_condencateur">
                        Read more
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </a>
                      <a
                        className="button button-border"
                        style={{ marginLeft: "250px" }}
                        href="/en/contact"
                      >
                        Contact us
                      </a>
                    </div>
                  </div>
                  {/** */}
                </div>
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  <div className="row mt-70">
                    <div
                      className="col-lg-12 col-md-12"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <img
                          className="img-fluid"
                          src="/assets/images/Power/bat-01.png"
                          alt="Batterie de Condensateurs
"
                          style={{ maxWidth: "80%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div>
        <Footer_en />
      </div>
    </div>
  );
};

export default Power_en;
