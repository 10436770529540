import React, { useEffect, useState } from "react";
import Loader from "../Loader";
import Footer_en from "./Footer_en";
import Header_en from "./Header_en";
const Filtreactive_en = () => {
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    //switch language to english
    document.documentElement.lang = "en";
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header_en />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        {/*=================================
page-title*/}
        <section
          className="page-title bg-overlay-black-60 parallax"
          data-jarallax='{"speed": 0.6}'
          style={{ backgroundImage: "url(/assets/images/slidebat.png)" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>Active anti-harmonic filter</h1>
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/en">
                      <i className="fa fa-home" /> Home
                    </a>{" "}
                    <i className="fa fa-angle-double-right" />
                  </li>
                  <li>
                    <span>Active filter</span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
page-title */}
        {/*=================================
 Premium Features */}
        <section className="service white-bg page-section-ptb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-title text-center">
                  <h2 className="title-effect">Active anti-harmonic filter</h2>
                </div>
              </div>
            </div>
            {/* ============================================ */}
            <div className="service-3">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="row slideInLeft wow">
                    <div className="service-blog text-Left">
                      <h3 className="theme-color">Application</h3>
                      <p>
                        Elimination/compensation of harmonics to improve the
                        quality and efficiency of electrical installations :
                      </p>
                      {/*<b>01</b>*/}
                      <ul
                        className="list list-unstyled list-hand"
                        style={{ borderRight: "0px solid white" }}
                      >
                        <li>
                          Harmonics generated by industrial machines (welding,
                          extruders, injection machines, etc.).
                        </li>
                        <li>
                          Elimination of the 3rd harmonic generated by
                          single-phase loads.
                        </li>
                        <li>Data centres.</li>
                        <li>UPS systems.</li>
                        <li>
                          Energy production (photovoltaic and wind power).
                        </li>
                        <li>Shopping centres.</li>
                        <li>Office buildings.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  <img
                    className="img-fluid full-width"
                    src="/assets/images/Power/filtre active2.png"
                    alt="Filtre active anti-harmoniques"
                  />
                  <div style={{ paddingTop: "80px" }}>
                    <a href="/contact" className="button button-border">
                      Contact us
                    </a>
                  </div>
                </div>
              </div>
              {/* ============================================ */}
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <img
                    className="img-fluid full-width"
                    src="/assets/images/Power/filtre active1.png"
                    alt="Filtre active anti-harmoniques"
                  />
                  <div style={{ paddingTop: "80px" }}>
                    <a href="/contact" className="button button-border">
                      Contact us
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  <div className="row slideInRight wow">
                    <div className="service-blog left text-left">
                      <h3 className="theme-color">Specifications</h3>
                      <p>
                        Compensation of harmonics up to the 50th harmonic
                        (individual selection of the harmonic to be compensated)
                      </p>
                      {/*<b>02</b>*/}
                      <ul
                        className="list list-unstyled list-hand"
                        style={{ borderLeft: "0px solid white" }}
                      >
                        <li>
                          Flicker compensator (voltage amplitude distortion).
                          voltage).
                        </li>
                        <li>Ultra-fast reactive compensation.</li>
                        <li>Compact design.</li>
                        <li>
                          Detection of resonance effects in the installation.
                        </li>
                        <li>High performance and reliability.</li>
                        <li>Easy-to-use navigation menu.</li>
                        <li>Quick and easy to install.</li>
                        <li>Fixed or modular.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* ============================================ */}
              {/* ============================================ */}
            </div>
          </div>
        </section>
        {/*============================Premium Features==*/}
      </div>
      <div>
        <Footer_en />
      </div>
    </div>
  );
};

export default Filtreactive_en;
