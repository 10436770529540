import React, { useEffect, useState } from "react";
import Header from "./Header";
import Loader from "./Loader";
import Footer from "./Footer";

const Actualites = () => {
  const handleClick = (event) => {
    event.preventDefault(); // Prevent the default action
    console.log("Link click prevented!");
  };
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        <section
          className="page-title bg-overlay-black-60 parallax"
          style={{
            backgroundImage:
              "url(/assets/images/actualité/slider-actualite.png)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>ACTUALITÉS</h1>
                  <p>Quoi De Neuf Chez SACEM ?</p>
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/">
                      <i className="fa fa-home" /> Accueil
                    </a>{" "}
                    <i className="fa fa-angle-double-right" />
                  </li>
                  <li>
                    <span>Actualités</span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
page-title */}
        {/*=================================
 Grid-1 */}
        <div className="page-section-ptb">
          <section className="page-section-pb white-bg">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="section-title text-center">
                    <h6>ACTUALITÉS</h6>
                    <h2 className="title-effect"> SACEM GROUP</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                {/** actu 1*/}
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="blog-entry mb-50">
                    <div className="entry-image clearfix">
                      <div
                        className="owl-carousel bottom-center-dots"
                        data-nav-dots="ture"
                        data-items={1}
                        data-md-items={1}
                        data-sm-items={1}
                        data-xs-items={1}
                        data-xx-items={1}
                      >
                        <div className="item">
                          <img
                            className="img-fluid"
                            src="/assets/images/actualité/home/Op-blanche01-01.jpg"
                            alt="action blanche SACEM"
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid"
                            src="/assets/images/actualité/home/Op-blanche02-02.jpg"
                            alt="action blanche SACEM"
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid"
                            src="/assets/images/actualité/home/Op-blanche03-03.jpg"
                            alt="action blanche SACEM"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="blog-detail" style={{ minHeight: "348px" }}>
                      <div className="entry-title mb-10">
                        <a href="#" onClick={handleClick}>
                          Action blanche pour lutte contre incendie, évacuation
                          et secourisme.
                        </a>
                      </div>
                      <div className="entry-meta mb-10">
                        <ul>
                          <li>
                            {" "}
                            <i className="fa fa-folder-open-o" />{" "}
                            <a href="#" onClick={handleClick}>
                              {" "}
                              Actualité Usine{" "}
                            </a>{" "}
                          </li>

                          <li>
                            <a href="#" onClick={handleClick}>
                              <i className="fa fa-calendar-o" /> 02 Décembre
                              2024
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="entry-content">
                        <p>
                          SACEM GROUP مجمع صـــاســام <br />
                          Retour sur l'action blanche pour lutte contre
                          incendie, évacuation et secourisme. Remerciements à
                          toute l'équipe de la protection civile de menzel
                          Bourguiba et aux personnels de SACEM GROUP مجمع
                          صـــاســام pour le bon déroulement de l'action.{" "}
                        </p>
                      </div>
                    </div>
                    <div
                      className="entry-share clearfix"
                      style={{
                        marginTop: "0px",
                        paddingBottom: "1.25rem",
                        paddingRight: "1.25rem",
                        paddingTop: "0.5rem",
                      }}
                    >
                      <div className="social list-style-none float-right">
                        <strong>Nous suivre: </strong>
                        <ul>
                          <li>
                            <a href="https://www.facebook.com/sacemindustries/?locale=fr_FR">
                              {" "}
                              <i className="fa fa-facebook" />{" "}
                            </a>
                          </li>
                          <li>
                            <a href="https://fr.linkedin.com/company/sacem-group">
                              {" "}
                              <i className="fa ti-linkedin" />{" "}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/** actu 2*/}
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="blog-entry mb-50">
                    <div className="entry-image clearfix">
                      <div
                        className="owl-carousel bottom-center-dots"
                        data-nav-dots="ture"
                        data-items={1}
                        data-md-items={1}
                        data-sm-items={1}
                        data-xs-items={1}
                        data-xx-items={1}
                      >
                        {/* <div className="item">
                          <img
                            className="img-fluid"
                            src="/assets/images/actualité/home/Op-blanche01-01.jpg"
                            alt="action blanche SACEM"
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid"
                            src="/assets/images/actualité/home/Op-blanche02-02.jpg"
                            alt="action blanche SACEM"
                          />
                        </div> */}
                        <div className="item">
                          <img
                            className="img-fluid"
                            src="/assets/images/actualité/home/ACT2.jpg"
                            alt="action blanche SACEM"
                            style={{ maxHeight: "309px" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="blog-detail" style={{ minHeight: "348px" }}>
                      <div className="entry-title mb-10">
                        <a href="#" onClick={handleClick}>
                          Réaménagement de la bibliothèque publique de Tinja
                        </a>
                      </div>
                      <div className="entry-meta mb-10">
                        <ul>
                          <li>
                            {" "}
                            <i className="fa fa-folder-open-o" />{" "}
                            <a href="#" onClick={handleClick}>
                              {" "}
                              Actualité Usine{" "}
                            </a>{" "}
                          </li>

                          <li>
                            <a href="#" onClick={handleClick}>
                              <i className="fa fa-calendar-o" /> 27 Septembre
                              2024
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="entry-content">
                        <p>
                          Dans le cadre de la responsabilité sociale de
                          l'entreprise, et suivant les instructions de M. Khaled
                          Kammoun, Directeur Général du Groupe SACEM, des
                          interventions ont été effectuées pour réaliser des
                          travaux d'entretien et de réparation pour le
                          réaménagement de la bibliothèque publique de Tinja.
                        </p>
                      </div>
                    </div>
                    <div
                      className="entry-share clearfix"
                      style={{
                        marginTop: "0px",
                        paddingBottom: "1.25rem",
                        paddingRight: "1.25rem",
                        paddingTop: "0.5rem",
                      }}
                    >
                      <div className="social list-style-none float-right">
                        <strong>Nous suivre: </strong>
                        <ul>
                          <li>
                            <a href="https://www.facebook.com/sacemindustries/?locale=fr_FR">
                              {" "}
                              <i className="fa fa-facebook" />{" "}
                            </a>
                          </li>
                          <li>
                            <a href="https://fr.linkedin.com/company/sacem-group">
                              {" "}
                              <i className="fa ti-linkedin" />{" "}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {/** */}
              </div>

              {/** row 2 */}
              <div className="row">
                {/** actu 1*/}
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="blog-entry mb-50">
                    <div className="entry-image clearfix">
                      <div
                        className="owl-carousel bottom-center-dots"
                        data-nav-dots="ture"
                        data-items={1}
                        data-md-items={1}
                        data-sm-items={1}
                        data-xs-items={1}
                        data-xx-items={1}
                      >
                        <div className="item">
                          <img
                            className="img-fluid"
                            src="/assets/images/actualité/home/sans-tabac.jpg"
                            alt="action blanche SACEM"
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid"
                            src="/assets/images/actualité/home/san-tabac2.jpg"
                            alt="action blanche SACEM"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="blog-detail" style={{ minHeight: "348px" }}>
                      <div className="entry-title mb-10">
                        <a href="#" onClick={handleClick}>
                          La journée mondiale sans tabac
                        </a>
                      </div>
                      <div className="entry-meta mb-10">
                        <ul>
                          <li>
                            {" "}
                            <i className="fa fa-folder-open-o" />{" "}
                            <a href="#" onClick={handleClick}>
                              {" "}
                              Actualité Usine{" "}
                            </a>{" "}
                          </li>

                          <li>
                            <a href="#" onClick={handleClick}>
                              <i className="fa fa-calendar-o" /> 31 Mai 2024
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="entry-content">
                        <p>
                          A l'occasion de la journée mondiale sans tabac le 31
                          mai 2024, une journée de sensibilisation a été
                          organisée à l'usine Sassam Industries à Menzel
                          Bourguiba-Tinja au profit des ouvriers et employés sur
                          les effets néfastes du tabagisme et ses répercussions
                          négatives sur la santé, en collaboration avec la
                          direction régionale de la santé à Bizerte et le
                          complexe de la médecine du travail à Bizerte.
                        </p>
                      </div>
                    </div>
                    <div
                      className="entry-share clearfix"
                      style={{
                        marginTop: "0px",
                        paddingBottom: "1.25rem",
                        paddingRight: "1.25rem",
                        paddingTop: "0.5rem",
                      }}
                    >
                      <div className="social list-style-none float-right">
                        <strong>Nous suivre: </strong>
                        <ul>
                          <li>
                            <a href="https://www.facebook.com/sacemindustries/?locale=fr_FR">
                              {" "}
                              <i className="fa fa-facebook" />{" "}
                            </a>
                          </li>
                          <li>
                            <a href="https://fr.linkedin.com/company/sacem-group">
                              {" "}
                              <i className="fa ti-linkedin" />{" "}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/** actu 2*/}
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="blog-entry mb-50">
                    <div className="entry-image clearfix">
                      <div
                        className="owl-carousel bottom-center-dots"
                        data-nav-dots="ture"
                        data-items={1}
                        data-md-items={1}
                        data-sm-items={1}
                        data-xs-items={1}
                        data-xx-items={1}
                      >
                        {/* <div className="item">
                          <img
                            className="img-fluid"
                            src="/assets/images/actualité/home/Op-blanche01-01.jpg"
                            alt="action blanche SACEM"
                          />
                        </div>
                        */}
                        <div className="item">
                          <img
                            className="img-fluid"
                            src="/assets/images/actualité/home/esprit1.jpg"
                            alt="action blanche SACEM"
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid"
                            src="/assets/images/actualité/home/esprit.jpg"
                            alt="action blanche SACEM"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="blog-detail" style={{ minHeight: "348px" }}>
                      <div>
                        <div className="entry-title mb-10">
                          <a href="#" onClick={handleClick}>
                            Accueil des étudiants d'ESPRIT
                          </a>
                        </div>
                        <div className="entry-meta mb-10">
                          <ul>
                            <li>
                              {" "}
                              <i className="fa fa-folder-open-o" />{" "}
                              <a href="#" onClick={handleClick}>
                                {" "}
                                Actualité Usine{" "}
                              </a>{" "}
                            </li>

                            <li>
                              <a href="#" onClick={handleClick}>
                                <i className="fa fa-calendar-o" /> 08 Mai 2024
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="entry-content">
                          <p>
                            Accueille des étudiants d'ESPRIT : École Supérieure
                            Privée d'Ingénierie et de Technologies dans notre
                            usine de Transformateurs. Nous avons eu
                            l'opportunité de leur présenter en détail le
                            processus de fabrication des différentes composantes
                            du transformateur électrique.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="entry-share clearfix"
                      style={{
                        marginTop: "0px",
                        paddingBottom: "1.25rem",
                        paddingRight: "1.25rem",
                        paddingTop: "0.5rem",
                      }}
                    >
                      <div className="social list-style-none float-right">
                        <strong>Nous suivre: </strong>
                        <ul>
                          <li>
                            <a href="https://www.facebook.com/sacemindustries/?locale=fr_FR">
                              {" "}
                              <i className="fa fa-facebook" />{" "}
                            </a>
                          </li>
                          <li>
                            <a href="https://fr.linkedin.com/company/sacem-group">
                              {" "}
                              <i className="fa ti-linkedin" />{" "}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {/** */}
              </div>
              {/** row 3 */}
              <div className="row">
                {/** actu 1*/}
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="blog-entry mb-50">
                    <div className="entry-image clearfix">
                      <div
                        className="owl-carousel bottom-center-dots"
                        data-nav-dots="ture"
                        data-items={1}
                        data-md-items={1}
                        data-sm-items={1}
                        data-xs-items={1}
                        data-xx-items={1}
                      >
                        <div className="item">
                          <img
                            className="img-fluid"
                            src="/assets/images/actualité/home/Petanque.jpg"
                            alt="action blanche SACEM"
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid"
                            src="/assets/images/actualité/home/Petanque1.jpg"
                            alt="action blanche SACEM"
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid"
                            src="/assets/images/actualité/home/Petanque2.jpg"
                            alt="action blanche SACEM"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="blog-detail" style={{ minHeight: "348px" }}>
                      <div className="entry-title mb-10">
                        <a href="#" onClick={handleClick}>
                          Premier tournoi amical de pétanque
                        </a>
                      </div>
                      <div className="entry-meta mb-10">
                        <ul>
                          <li>
                            {" "}
                            <i className="fa fa-folder-open-o" />{" "}
                            <a href="#" onClick={handleClick}>
                              {" "}
                              Actualité Usine{" "}
                            </a>{" "}
                          </li>

                          <li>
                            <a href="#" onClick={handleClick}>
                              <i className="fa fa-calendar-o" /> 18 Février 2024
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="entry-content">
                        <p>
                          Le GROUPE SACEM tient à remercier tous les
                          participants du premier tournoi amical de pétanque du
                          18 février 2024 au complexe sportif de la SACEM. Il
                          félicite également les vainqueurs de l'association de
                          la fédération du domicile et de l'association des
                          jeunes de Tinja et leur souhaite plus d'éclat dans les
                          compétitions à venir.
                        </p>
                      </div>
                    </div>
                    <div
                      className="entry-share clearfix"
                      style={{
                        marginTop: "0px",
                        paddingBottom: "1.25rem",
                        paddingRight: "1.25rem",
                        paddingTop: "0.5rem",
                      }}
                    >
                      <div className="social list-style-none float-right">
                        <strong>Nous suivre: </strong>
                        <ul>
                          <li>
                            <a href="https://www.facebook.com/sacemindustries/?locale=fr_FR">
                              {" "}
                              <i className="fa fa-facebook" />{" "}
                            </a>
                          </li>
                          <li>
                            <a href="https://fr.linkedin.com/company/sacem-group">
                              {" "}
                              <i className="fa ti-linkedin" />{" "}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/** actu 2*/}
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="blog-entry mb-50">
                    <div className="entry-image clearfix">
                      <div
                        className="owl-carousel bottom-center-dots"
                        data-nav-dots="ture"
                        data-items={1}
                        data-md-items={1}
                        data-sm-items={1}
                        data-xs-items={1}
                        data-xx-items={1}
                      >
                        {/* <div className="item">
                          <img
                            className="img-fluid"
                            src="/assets/images/actualité/home/Op-blanche01-01.jpg"
                            alt="action blanche SACEM"
                          />
                        </div>
                        */}
                        <div className="item">
                          <img
                            className="img-fluid"
                            src="/assets/images/actualité/home/ELEK_ENER_2023.jpg"
                            alt="action blanche SACEM"
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid"
                            src="/assets/images/actualité/home/ELEK_ENER_2023_3.jpg"
                            alt="action blanche SACEM"
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid"
                            src="/assets/images/actualité/home/ELEK_ENER_2023_2.jpg"
                            alt="action blanche SACEM"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="blog-detail" style={{ minHeight: "348px" }}>
                      <div>
                        <div className="entry-title mb-10">
                          <a href="#" onClick={handleClick}>
                            Salon de l’Electricité et des Energies Renouvelables
                            ELEK ENER 2023
                          </a>
                        </div>
                        <div className="entry-meta mb-10">
                          <ul>
                            <li>
                              {" "}
                              <i className="fa fa-folder-open-o" />{" "}
                              <a href="#" onClick={handleClick}>
                                {" "}
                                Actualité Usine{" "}
                              </a>{" "}
                            </li>

                            <li>
                              <a href="#" onClick={handleClick}>
                                <i className="fa fa-calendar-o" /> 11 - 15
                                October 2023
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="entry-content">
                          <p>
                            Retour sur notre participation au 2ème Salon de
                            l'électricité et des énergies renouvelables ELEK
                            ENER 2023, qui s'est tenu du 11 au 15 octobre au
                            Parc des Expositions du Kram à Tunis, avec notre
                            partenaire Medelec Switchgear.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="entry-share clearfix"
                      style={{
                        marginTop: "0px",
                        paddingBottom: "1.25rem",
                        paddingRight: "1.25rem",
                        paddingTop: "0.5rem",
                      }}
                    >
                      <div className="social list-style-none float-right">
                        <strong>Nous suivre: </strong>
                        <ul>
                          <li>
                            <a href="https://www.facebook.com/sacemindustries/?locale=fr_FR">
                              {" "}
                              <i className="fa fa-facebook" />{" "}
                            </a>
                          </li>
                          <li>
                            <a href="https://fr.linkedin.com/company/sacem-group">
                              {" "}
                              <i className="fa ti-linkedin" />{" "}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/** row 3*/}
            </div>
          </section>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Actualites;
