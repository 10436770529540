import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Loader from "../Loader";
import Footer_en from "./Footer_en";
import Header_en from "./Header_en";

const Transformateur_en = () => {
  const StyledHeading = styled.h6`
    position: relative;
    font-size: 100px;
    color: #008bcd;

    &::before {
      content: "—";
      font-size: inherit;
      color: #008bcd;
      display: block;
    }
  `;
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    //switch language to english
    document.documentElement.lang = "en";
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header_en />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        <section
          className="page-title bg-overlay-black-60 parallax"
          style={{
            backgroundImage:
              "url(/assets/images/transformateur/Triphase/slider-triphase-36-24.png)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>SACEM INDUSTRIES</h1>
                  <p>Innovative Technologies to Bring Electricity</p>
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/en">
                      <i className="fa fa-home"></i> Home
                    </a>{" "}
                    <i className="fa fa-angle-double-right"></i>
                  </li>
                  <li>
                    <span>Transformer</span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/*=================================
page-title */}
        {/*=================================
 feature */}
        <section className="service white-bg page-section-ptb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-title text-center">
                  <h2 className="title-effect">
                    Our Transformer production range
                  </h2>
                </div>
              </div>
            </div>
            {/* ============================================ */}
            <div className="service-3">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  {/** */}
                  <div
                    className="section-title slideInLeft wow"
                    style={{
                      visibility: "visible",
                      animationName: "slideInLeft",
                    }}
                  >
                    <div
                      className="service-blog text-left"
                      style={{ marginBottom: "50px" }}
                    >
                      <h3 className="theme-color">
                        Three-phase distribution transformers
                      </h3>
                      <StyledHeading className="h6-line"></StyledHeading>
                      <p>
                        Our three-phase distribution transformers are designed
                        to ensure safe, reliable and efficient power
                        distribution.
                      </p>
                      <ul
                        className="list list-unstyled list-hand"
                        style={{ borderRight: "0px solid white" }}
                      >
                        <li>Oil immersed </li>
                        <li>Hermetically sealed or with conservator</li>
                        <li>ONAN cooling</li>
                        <li>Indoor/outdoor installation</li>
                        <li>
                          Colour: light grey - RAL 7035 (other colours on
                          request)
                        </li>
                      </ul>
                    </div>

                    <div className="entry-button">
                      <a
                        className="button arrow"
                        href="/transformateur_dist_36"
                      >
                        Read more
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </a>
                      <a
                        className="button button-border"
                        style={{ marginLeft: "250px" }}
                        href="/en/contact"
                      >
                        Contact us
                      </a>
                    </div>
                  </div>

                  {/** */}
                </div>
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  <div className="row mt-70">
                    <div className="col-lg-12 col-md-12">
                      <div>
                        <img
                          className="img-fluid"
                          src="/assets/images/transformateur/Distrubition/Transfo-DST1.png"
                          alt="Transformateurs de distribution triphasés"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ============================================ */}
              <div className="row">
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  <div className="row mt-70">
                    <div>
                      <img
                        className="img-fluid"
                        src="/assets/images/transformateur/Puissance/20P2-Photoroom.png"
                        alt="Transformateurs de puissance Triphasés"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  {/** */}
                  <div
                    className="section-title slideInRight wow"
                    style={{
                      visibility: "visible",
                      animationName: "slideInRight",
                    }}
                  >
                    <div
                      className="service-blog left text-left"
                      style={{ marginBottom: "50px" }}
                    >
                      <h3 className="theme-color">
                        Three-phase power transformers
                      </h3>
                      <StyledHeading className="h6-line"></StyledHeading>
                      <p>
                        Our three-phase power transformers are designed to meet
                        customer requirements for electrical power distribution,
                        guaranteeing optimum performance, high energy efficiency
                        and long-term reliability.
                      </p>
                      <ul
                        className="list list-unstyled list-hand"
                        style={{ borderLeft: "0px solid white" }}
                      >
                        <li>Oil immersed</li>
                        <li>ONAN and/or ONAF cooling </li>
                        <li>Indoor/Outdoor installation</li>
                        <li>Meeting the requirements of IEC 60076</li>
                      </ul>
                    </div>
                    <div className="entry-button">
                      <a
                        className="button arrow"
                        href="/transformateur_pui_triph"
                      >
                        Read more
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </a>
                      <a
                        className="button button-border"
                        style={{ marginLeft: "250px" }}
                        href="/en/contact"
                      >
                        Contact us
                      </a>
                    </div>
                  </div>
                  {/** */}
                </div>
              </div>
              {/* ============================================ */}
              <div className="row">
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  {/** */}
                  <div
                    className="section-title slideInLeft wow"
                    style={{
                      visibility: "visible",
                      animationName: "slideInLeft",
                    }}
                  >
                    <div
                      className="service-blog text-left"
                      style={{ marginBottom: "50px" }}
                    >
                      <h3 className="theme-color">Single-phase transformers</h3>
                      <StyledHeading className="h6-line"></StyledHeading>
                      <p>
                        Discover our range of single-phase transformers,
                        designed to meet your power supply needs with
                        reliability and performance.
                      </p>
                      <ul
                        className="list list-unstyled list-hand"
                        style={{ borderRight: "0px solid white" }}
                      >
                        <li>Oil immersed</li>
                        <li>ONAN cooling</li>
                        <li>Indoor/outdoor installation</li>
                        <li>
                          Colour: light grey - RAL 7035 (other colours on
                          request)
                        </li>
                      </ul>
                    </div>
                    <div className="entry-button">
                      <a className="button arrow" href="/transformateur_mono">
                        Read more
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </a>
                      <a
                        className="button button-border"
                        style={{ marginLeft: "250px" }}
                        href="/en/contact"
                      >
                        Contact us
                      </a>
                    </div>
                  </div>
                  {/** */}
                </div>
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  <div className="row mt-70">
                    <div className="col-lg-12 col-md-12">
                      <div>
                        <img
                          className="img-fluid"
                          src="/assets/images/transformateur/Mono/MONO.png"
                          alt="Transformateurs Monophasés"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ============================================ */}
              <div className="row">
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  <div className="row mt-70">
                    <div>
                      <img
                        className="img-fluid"
                        src="/assets/images/transformateur/Specials/special.png"
                        alt="Transformateurs Spéciaux"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  {/** */}
                  <div
                    className="section-title slideInRight wow"
                    style={{
                      visibility: "visible",
                      animationName: "slideInRight",
                    }}
                  >
                    <div
                      className="service-blog left text-left"
                      style={{ marginBottom: "50px" }}
                    >
                      <h3 className="theme-color">Special Transformers</h3>
                      <StyledHeading className="h6-line"></StyledHeading>
                      <p>
                        Our production range also includes transformers for
                        specific applications.
                        <br />
                        Our experienced design team can to meet your needs for
                        transformers with specific dimensions or non-standard
                        configurations.
                      </p>

                      <ul
                        className="list list-unstyled list-hand"
                        style={{ borderLeft: "0px solid white" }}
                      >
                        <li>Earthing transformers</li>
                        <li>Autotransformer</li>
                        <li>Isolation transformer</li>
                      </ul>
                    </div>
                    <div className="entry-button">
                      <a
                        className="button arrow"
                        href="/transformateur_special"
                      >
                        Read more
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </a>
                      <a
                        className="button button-border"
                        style={{ marginLeft: "250px" }}
                        href="/en/contact"
                      >
                        Contact us
                      </a>
                    </div>
                  </div>
                  {/** */}
                </div>
              </div>
              {/* ============================================ */}
            </div>
            <div className="row mt-70">
              <div className="col-lg-12 col-md-12">
                <h2 className="tile-headline">Our certificates</h2>
                <StyledHeading></StyledHeading>
                {/**<div className="divider double" />*/}
                <br />
                <br />
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="clients-list">
                <div
                  className="owl-carousel"
                  data-nav-dots="false"
                  data-items={3}
                  data-md-items={4}
                  data-sm-items={3}
                  data-xs-items={2}
                  data-xx-items={2}
                >
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/Certificat/cesi.png"
                      alt="cesi"
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/Certificat/veiki.png"
                      alt="veiki"
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid mx-auto"
                      style={{ height: 80 }}
                      src="/assets/images/Certificat/kema.png"
                      alt="kema"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div>
        <Footer_en />
      </div>
    </div>
  );
};

export default Transformateur_en;
