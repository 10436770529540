import React, { useEffect, useState } from "react";
import Loader from "../Loader";
import styled from "styled-components";
import Footer_en from "./Footer_en";
import Header_en from "./Header_en";

const Transformateur_pui_TRIPH_en = () => {
  const StyledHeading = styled.h6`
    position: relative;
    font-size: 100px;
    color: #008bcd;

    &::before {
      content: "—";
      font-size: inherit;
      color: #008bcd;
      display: block;
    }
  `;
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    //switch language to english
    document.documentElement.lang = "en";
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header_en />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        {/*=================================
page-title*/}
        <section
          className="page-title bg-overlay-black-60 parallax"
          style={{
            backgroundImage:
              "url(/assets/images/transformateur/Triphase/slider-triphase-36-24.png)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>SACEM INDUSTRIES</h1>
                  <p>innovative Technologies to Bring Electricity</p>
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/en">
                      <i className="fa fa-home" /> Home
                    </a>{" "}
                    <i className="fa fa-angle-double-right" />
                  </li>
                  <li>
                    <span>Three-phase Power transformer</span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
page-title */}
        {/*=================================
 feature */}
        <section className="service white-bg page-section-ptb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-title text-center">
                  <h2 className="title-effect">
                    Three-phase Power transformer
                  </h2>
                </div>
              </div>
            </div>
            {/* ============================================ */}
            {/*=====================================*/}
            {/**<section className="page-section-ptb"> */}
            <section>
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-sm-6 mb-30 ">
                    {/** */}
                    <div
                      className="rotateInDownLeft wow"
                      style={{
                        visibility: "visible",
                        animationName: "rotateInDownLeft",
                      }}
                    >
                      <div
                        className="media border p-4"
                        style={{ minHeight: "355px" }}
                      >
                        <div className="feature-icon media-icon mr-4">
                          <span className="ti-settings theme-color1" />
                        </div>
                        <div className="media-body">
                          <h5 className="text-back">GENERAL CHARACTERISTICS</h5>
                          <br />
                          <p className="mb-0">
                            Oil immersed, Three-phase power transformer:
                          </p>
                          <br />
                          <ul className="list list-unstyled list-hand">
                            <li>IEC 60076 standard.</li>
                            <li>Conservator type.</li>
                            <li>ONAN and/or ONAF cooling.</li>
                            <li>Indoor/outdoor installation.</li>
                            <li>
                              Colour: RAL 7035 (other colours on request).
                            </li>
                            <li>Corrosion protection: up to C5-H</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/** */}
                  </div>
                  <div className="col-lg-6 col-sm-6 mb-30 ">
                    {/** */}
                    <div
                      className="rotateInDownRight wow"
                      style={{
                        visibility: "visible",
                        animationName: "rotateInDownRight",
                      }}
                    >
                      <div
                        className="media border p-4"
                        style={{ minHeight: "355px" }}
                      >
                        <div className="feature-icon media-icon mr-4">
                          <span className="ti-bolt theme-color1" />
                        </div>
                        <div className="media-body">
                          <h5 className="text-back">NOMINAL VOLTAGES</h5>
                          <br />
                          <ul className="list list-unstyled list-hand">
                            <li>Voltage Class: 24 & 36 kV.</li>
                            <li>Coupling: Dyn11, YNd11, YNyn0.</li>
                            <li>Frequency: 50Hz, 60Hz.</li>
                            <li>Conductor: Copper.</li>
                            <li>
                              Insulation fluid: Mineral oil according to IEC
                              60296.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/** */}
                  </div>
                  <div className="col-lg-6 col-sm-6 xs-mb-30">
                    {/** */}
                    <div
                      className="rotateInUpLeft wow"
                      style={{
                        visibility: "visible",
                        animationName: "rotateInUpLeft",
                      }}
                    >
                      <div
                        className="media border p-4"
                        style={{ minHeight: "795px" }}
                      >
                        <div className="feature-icon media-icon mr-4">
                          <span className="ti-package theme-color1" />
                        </div>
                        <div className="media-body">
                          <h5 className="text-back">BASIC EQUIPMENT </h5>
                          <br />
                          <ul className="list list-unstyled list-hand">
                            {" "}
                            <li>Radiators.</li>
                            <li>Conservator tank.</li>
                            <li>Dehydrating air breather.</li>
                            <li>Marshalling box.</li>
                            <li>Conservator drain valve.</li>
                            <li>Earthing terminals.</li>
                            <li>Treatment valve.</li>
                            <li>Pressure relief device.</li>
                            <li>Porcelain or plug-in bushings.</li>
                            <li>Buchholz relay.</li>
                            <li>Oil level indicator.</li>
                            <li>Dial type thermometer.</li>
                            <li>Rating plate.</li>
                            <li>Drain valve with sampling facility.</li>
                            <li>Rollers.</li>
                            <li>Thermowell.</li>
                            <li>OLTC or DETC.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/** */}
                  </div>
                  <div className="col-lg-6 col-sm-6">
                    {/** */}
                    <div
                      className="rotateInUpRight wow"
                      style={{
                        visibility: "visible",
                        animationName: "rotateInUpRight",
                      }}
                    >
                      <div
                        className="media border p-4"
                        style={{ minHeight: "795px" }}
                      >
                        <div className="feature-icon media-icon mr-4">
                          <span className="ti-list theme-color1" />
                        </div>
                        <div className="media-body">
                          <h5 className="text-back">OPTIONAL EQUIPMENT</h5>
                          <br />
                          <ul className="list list-unstyled list-hand">
                            {" "}
                            <li>Shutter valve</li>
                            <li>Winding Temperature Indicator</li>{" "}
                          </ul>
                          <p style={{ fontWeight: "bold" }}>
                            Note: the above list is not exhaustive.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/** */}
                </div>
              </div>
              <div>
                {/*=================================
 Tableau */}
                <section id="tab36" className="page-section-ptb data-table">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <h3 style={{ color: "#00356a" }}>
                          ELECTRICAL CHARACTERISTICS{" "}
                        </h3>
                        <StyledHeading className="h6-line"></StyledHeading>
                        <br />
                        <div className="table-responsive">
                          <table className="table table-1 table-bordered table-striped">
                            <thead>
                              <tr>
                                <td colSpan={3}>
                                  Power {"{"}kVA{"}"}
                                </td>
                                <td>4000</td>
                                <td>5000</td>
                                <td>6300</td>
                                <td>8000</td>
                                <td>10000</td>
                                <td>12000</td>
                                <td>15000</td>
                                <td>20000</td>
                                <td>25000</td>
                                <td>26000</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td colSpan={3}>
                                  No-load losses {"{"}W{"}"}
                                </td>
                                <td>5000</td>
                                <td>6000</td>
                                <td>6800</td>
                                <td>9200</td>
                                <td>10000</td>
                                <td>11600</td>
                                <td>13000</td>
                                <td>14000</td>
                                <td>18500</td>
                                <td>20000</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>
                                  Load losses {"{"}W{"}"}
                                </td>
                                <td>42000</td>
                                <td>50000</td>
                                <td>52000</td>
                                <td>57000</td>
                                <td>65000</td>
                                <td>76000</td>
                                <td>85000</td>
                                <td>96000</td>
                                <td>115000</td>
                                <td>150000</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>
                                  No-load current {"{"}%{"}"}
                                </td>
                                <td>2.5</td>
                                <td>2.3</td>
                                <td>2.2</td>
                                <td>2</td>
                                <td>1.9</td>
                                <td>1.8</td>
                                <td>1.7</td>
                                <td>1.6</td>
                                <td>1.5</td>
                                <td>1.2</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>
                                  Short circuit impedance {"{"}%{"}"}
                                </td>
                                <td>8</td>
                                <td>8</td>
                                <td>8</td>
                                <td>8</td>
                                <td>9</td>
                                <td>9</td>
                                <td>10</td>
                                <td>10</td>
                                <td>10</td>
                                <td>10</td>
                              </tr>
                              <tr>
                                <td>
                                  Voltage drop {"{"}%{"}"}
                                </td>
                                <td colSpan={2}>Cos φ= 0.8</td>
                                <td>4.88</td>
                                <td>5.37</td>
                                <td>5.43</td>
                                <td>5.35</td>
                                <td>5.90</td>
                                <td>5.89</td>
                                <td>6.54</td>
                                <td>6.38</td>
                                <td>6.66</td>
                                <td>6.41</td>
                              </tr>
                              <tr>
                                <td>
                                  Voltage drop {"{"}%{"}"}
                                </td>
                                <td colSpan={2}>Cos φ= 1</td>
                                <td>0.10</td>
                                <td>0.73</td>
                                <td>0.82</td>
                                <td>0.71</td>
                                <td>0.65</td>
                                <td>0.63</td>
                                <td>0.70</td>
                                <td>0.48</td>
                                <td>0.96</td>
                                <td>1.03</td>
                              </tr>
                              <tr>
                                <td>
                                  Efficiency {"{"}%{"}"} (à Cos φ=0.8)
                                </td>
                                <td>Load %</td>
                                <td>50</td>
                                <td>99.90</td>
                                <td>99.23</td>
                                <td>99.22</td>
                                <td>99.27</td>
                                <td>99.35</td>
                                <td>99.37</td>
                                <td>99.37</td>
                                <td>99.53</td>
                                <td>99.53</td>
                                <td>99.45</td>
                              </tr>
                              <tr>
                                <td>
                                  Performance {"{"}%{"}"} (à Cos φ=0.8)
                                </td>
                                <td>Charge en %</td>
                                <td>75</td>
                                <td>99.88</td>
                                <td>99.11</td>
                                <td>99.06</td>
                                <td>99.15</td>
                                <td>99.23</td>
                                <td>99.25</td>
                                <td>99.22</td>
                                <td>99.44</td>
                                <td>99.45</td>
                                <td>99.34</td>
                              </tr>
                              <tr>
                                <td>
                                  Performance {"{"}%{"}"} (à Cos φ=0.8)
                                </td>
                                <td>Charge en %</td>
                                <td>100</td>
                                <td>99.85</td>
                                <td>98.93</td>
                                <td>98.85</td>
                                <td>98.98</td>
                                <td>99.07</td>
                                <td>99.10</td>
                                <td>99.03</td>
                                <td>99.32</td>
                                <td>99.34</td>
                                <td>99.19</td>
                              </tr>
                            </tbody>
                          </table>
                          {/*=================================
 Tableau */}
                          <br />
                          <br />
                          <br />
                          <h3 style={{ color: "#00356a" }}>
                            DIMENSIONS AND WEIGHTS
                          </h3>
                          <StyledHeading className="h6-line"></StyledHeading>
                          <br />
                          <table className="table table-1 table-bordered table-striped">
                            <thead>
                              <tr>
                                <td colSpan={3}>Power (kVA)</td>
                                <td>4000</td>
                                <td>5000</td>
                                <td>6300</td>
                                <td>8000</td>
                                <td>10000</td>
                                <td>12000</td>
                                <td>15000</td>
                                <td>20000</td>
                                <td>25000</td>
                                <td>26000</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td colSpan={3}>L</td>
                                <td>3050</td>
                                <td>3400</td>
                                <td>4000</td>
                                <td>4520</td>
                                <td>4650</td>
                                <td>4800</td>
                                <td>4900</td>
                                <td>5150</td>
                                <td>4270</td>
                                <td>4300</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>W</td>
                                <td>2560</td>
                                <td>2750</td>
                                <td>2700</td>
                                <td>3080</td>
                                <td>3120</td>
                                <td>3200</td>
                                <td>3650</td>
                                <td>4000</td>
                                <td>3490</td>
                                <td>4500</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>H</td>
                                <td>3000</td>
                                <td>3250</td>
                                <td>3520</td>
                                <td>3600</td>
                                <td>3900</td>
                                <td>4200</td>
                                <td>4400</td>
                                <td>4800</td>
                                <td>3870</td>
                                <td>5000</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>E</td>
                                <td>1000</td>
                                <td>1000</td>
                                <td>1000</td>
                                <td>1200</td>
                                <td>1200</td>
                                <td>1200</td>
                                <td>1500</td>
                                <td>1500</td>
                                <td>1500</td>
                                <td>1500</td>
                              </tr>
                              <tr>
                                <td colSpan={3}> Oil Weight (Kg)</td>
                                <td>2200</td>
                                <td>2850</td>
                                <td>3200</td>
                                <td>4000</td>
                                <td>4900</td>
                                <td>6000</td>
                                <td>8550</td>
                                <td>10300</td>
                                <td>8450</td>
                                <td>10700</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>Total weight (kg)</td>
                                <td>9500</td>
                                <td>12300</td>
                                <td>14000</td>
                                <td>18500</td>
                                <td>22000</td>
                                <td>29000</td>
                                <td>33500</td>
                                <td>42000</td>
                                <td>41000</td>
                                <td>46500</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="entry-button" style={{ marginLeft: 20 }}>
                      <a className="button button-border" href="/en/contact">
                        Contact us
                      </a>
                    </div>
                    <div className="entry-button" style={{ marginLeft: 20 }}>
                      <a
                        className="button button-border"
                        href="/assets/brochures/catalogue transformateurs 2024 source.pdf"
                      >
                        Catalogue
                      </a>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </section>
      </div>
      <div>
        <Footer_en />
      </div>
    </div>
  );
};

export default Transformateur_pui_TRIPH_en;
